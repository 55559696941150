export const Resolutions = [
  "426x240",
  "640x360",
  "854x480",
];

export const FPSs = [24, 30];

export const maxViewCounts = [2, 5, 10];

export const PlabackSpeeds = [1, 1.2, 1.5];
