import React, { useContext } from "react";
import { Grid } from "@material-ui/core";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import { Link, useRouteMatch } from "react-router-dom";
import { Button } from "@material-ui/core";
import CustomCard from "../../ui/CustomCard/CustomCard";
import { CourseContext } from "../../context/CourseContext/CourseContext";
import { AuthContext } from "../../context/AuthContext/AuthContext";

export default function CoursesCardList() {
  let { url } = useRouteMatch();
  const { role, profile, userPayments, updateUser } = useContext(AuthContext);

  const { coursesTableData } = useContext(CourseContext);

  return (
    <>
      {coursesTableData &&
        coursesTableData.map((course, index) => {
          const {
            id,
            title,
            day,
            image,
            startTime,
            endTime,
            description,
          } = course;
          // console.log("userPayments");
          // console.log(
          //   userPayments,
          //   userPayments &&
          //     userPayments.filter((val) => val.course === id).length
          // );
          return (
            <Grid key={index} item xs={12} md={4} lg={4}>
              <CustomCard
                title={title}
                subheader={day + " (" + startTime + "-" + endTime + ")"}
                description={description}
                image={image}
              >
                {role === 1 ? (
                  profile && profile.courses && profile.courses.includes(id) ? (
                    userPayments &&
                      userPayments.map((val) => val.course).includes(id) ? (
                      <Link
                        to={{
                          pathname: `${url}/${title}`,
                          state: {
                            params: { course: id },
                          },
                        }}
                      >
                        <Button
                          startIcon={<ArrowForwardIcon />}
                          fullWidth={true}
                          variant="contained"
                          color="primary"
                        >
                          Go to course
                        </Button>
                      </Link>
                    ) : (
                      <Button
                        // startIcon={<ArrowForwardIcon />}
                        fullWidth={true}
                        variant="contained"
                        color="secondary"
                      >
                        Access Denied
                      </Button>
                    )
                  ) : (
                    <Button
                      // startIcon={<ArrowForwardIcon />}
                      onClick={() =>
                        updateUser(
                          {
                            courses: profile.courses
                              ? [...profile.courses, id]
                              : [id],
                          },
                          () => { }
                        )
                      }
                      fullWidth={true}
                      variant="contained"
                      color="secondary"
                    >
                      Enroll Now
                    </Button>
                  )
                ) : role === 0 ? (
                  <Link
                    to={{
                      pathname: `${url}/${title}`,
                      state: {
                        params: { course: id },
                      },
                    }}
                  >
                    <Button
                      startIcon={<ArrowForwardIcon />}
                      fullWidth={true}
                      variant="contained"
                      color="primary"
                    >
                      Go to course
                    </Button>
                  </Link>
                ) : null}
              </CustomCard>
            </Grid>
          );
        })}
    </>
  );
}
