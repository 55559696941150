import React, { useContext, useEffect, useState } from "react";
import CustomAccordionGroup from "./CustomAccordionGroup/CustomAccordionGroup";
import SectionForm from "./SectionForm";
import CustomDialog from "../../ui/CustomDialog/CustomDialog";
import SectionContentForm from "./SectionForm/SectionContentForm";
import { useHistory, useLocation, useRouteMatch } from "react-router-dom";
import { CourseContext } from "../../context/CourseContext/CourseContext";
import { AuthContext } from "../../context/AuthContext/AuthContext";
import { useSnackbar } from "notistack";

export default function AllLessonsList() {
  const { state } = useLocation();
  const history = useHistory();
  let { course } = state.params;
  const {
    loading,
    courseModules,
    courseModuleContents,
    getCourseModules,
    updateCourseModule,
    deleteCourseModule,
    getCourseModuleContents,
    updateCourseModuleContent,
    updateStudentCourseModuleContent,
    checkAccessToCourseModuleContent,
    deleteCourseModuleContent,
  } = useContext(CourseContext);

  const { enqueueSnackbar } = useSnackbar();
  const { user, role } = useContext(AuthContext);
  const [showSectionModal, setShowSectionModal] = useState(false);
  const [showSectionContentModal, setShowSectionContentModal] = useState(false);
  const [section, setSection] = useState(0);
  const [content, setContent] = useState(0);
  const [newContentType, setNewContentType] = useState(0);
  const [showNewSectionContentModal, setNewShowSectionContentModal] = useState(
    false
  );
  const [expanded, setExpanded] = useState(false);

  const onChangeExpanded = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
    getCourseModuleContents(courseModules[panel].id);
  };
  const onEditSection = (index) => {
    setSection(index);
    setShowSectionModal(true);
  };

  const onDeleteSection = (index) => {
    deleteCourseModule(courseModules[index].id);
  };

  const onHideSection = (index) => {
    updateCourseModule(courseModules[index].id, {
      visibility: !courseModules[index].visibility,
    });
  };

  const onEditSectionContent = (index1, index2) => {
    setSection(index1);
    setContent(index2);
    setShowSectionContentModal(true);
  };

  const onDeleteSectionContent = (index1, index2) => {
    deleteCourseModuleContent(courseModuleContents[index2].id, courseModuleContents[index2].file, courseModuleContents[index2].type, courseModuleContents[index2].contentType);
  };

  const onHideSectionContent = (index1, index2) => {
    updateCourseModuleContent(
      courseModuleContents[index2].id,
      {
        visibility: !courseModuleContents[index2].visibility,
      },
      setShowSectionContentModal
    );
  };

  const onAddFile = (type, index1) => {
    setSection(index1);
    setNewContentType(type);
    setNewShowSectionContentModal(true);
  };

  const { url } = useRouteMatch();

  const handleGotoCourseModuleContent = async (item_no) => {
    const courseModuleContentId = courseModuleContents[item_no].id;
    const maxCount = courseModuleContents[item_no].max_view_count;
    const type = courseModuleContents[item_no].type;
    const allViewCount = courseModuleContents[item_no].view_count;
    const fileTemp = courseModuleContents[item_no].file;
    const descriptionTemp = courseModuleContents[item_no].description;
    const titleTemp = courseModuleContents[item_no].title;
    const downloadable = courseModuleContents[item_no].downloadable;
    const userId = user.uid;
    // console.log(type);
    if (role === 0) {
      history.push({
        pathname: `${url}/${titleTemp}`,
        state: {
          params: { file: fileTemp, description: descriptionTemp, type: type, downloadable: downloadable },
        },
      });
    } else {
      if (type === 1) {
        enqueueSnackbar("Checking access...", { variant: "info" });
        // console.log(courseModuleContentId, userId);
        const data = await checkAccessToCourseModuleContent(
          courseModuleContentId,
          userId
        );
        // console.log(data);
        if (data === undefined) {
          updateStudentCourseModuleContent(courseModuleContentId, userId, {
            view_count: 1,
          });
          updateCourseModuleContent(
            courseModuleContentId,
            {
              view_count: allViewCount + 1,
            },
            () => null
          );

          enqueueSnackbar("Access granted!", { variant: "success" });
          history.push({
            pathname: `${url}/${titleTemp}`,
            state: {
              params: {
                file: fileTemp,
                description: descriptionTemp,
                type: type,
                remaining: maxCount,
              },
            },
          });
        } else {
          if (maxCount) {

          }
          if (data.view_count < maxCount) {
            updateStudentCourseModuleContent(courseModuleContentId, userId, {
              view_count: data.view_count + 1,
            });
            updateCourseModuleContent(
              courseModuleContentId,
              {
                view_count: allViewCount + 1,
              },
              () => null
            );

            enqueueSnackbar("Access granted!", { variant: "success" });
            history.push({
              pathname: `${url}/${titleTemp}`,
              state: {
                params: {
                  file: fileTemp,
                  description: descriptionTemp,
                  type: type,
                  remaining: maxCount - data.view_count,
                },
              },
            });
          } else {
            enqueueSnackbar("Access denied!", { variant: "error" });
          }
        }
      } else {
        updateCourseModuleContent(
          courseModuleContentId,
          {
            view_count: allViewCount + 1,
          },
          () => null
        );

        history.push({
          pathname: `${url}/${titleTemp}`,
          state: {
            params: {
              file: fileTemp,
              description: descriptionTemp,
              type: type,
              downloadable: downloadable
            },
          },
        });
      }
    }
  };

  useEffect(() => {
    getCourseModules(course);
  }, []);

  // if (loading) {
  //   return null;
  // }
  // console.log(loading);
  return (
    <>
      {courseModules &&
        courseModules.map((sec, index) => (
          <div style={{ width: "100%" }} key={index}>
            <CustomAccordionGroup
              section={index}
              loading={loading}
              expanded={expanded}
              onChangeExpanded={onChangeExpanded}
              data={courseModuleContents ? courseModuleContents : []}
              title={sec.title}
              visibilitySection={courseModules[index].visibility}
              onEditSection={onEditSection}
              onDeleteSection={onDeleteSection}
              onHideSection={onHideSection}
              onEditSectionContent={onEditSectionContent}
              onDeleteSectionContent={onDeleteSectionContent}
              onHideSectionContent={onHideSectionContent}
              onAddFile={onAddFile}
              handleGotoCourseModuleContent={handleGotoCourseModuleContent}
            />
            <div style={{ marginTop: "2px" }} />
          </div>
        ))}
      {courseModules && courseModules[section] && (
        <>
          <CustomDialog
            maxWidth={"md"}
            title="Edit Section"
            open={showSectionModal}
            setOpen={setShowSectionModal}
          >
            <SectionForm
              setOpen={setShowSectionModal}
              id={courseModules[section].id}
              data={courseModules[section].data}
              title={courseModules[section].title}
              description={courseModules[section].description}
            />
          </CustomDialog>
          {courseModuleContents && courseModuleContents[content] && (
            <>
              <CustomDialog
                maxWidth={"md"}
                title="Edit Section Content"
                open={showSectionContentModal}
                setOpen={setShowSectionContentModal}
              >
                <SectionContentForm
                  action={1}
                  setOpen={setShowSectionContentModal}
                  courseModule={courseModuleContents[content].courseModule}
                  id={courseModuleContents[content].id}
                  type={courseModuleContents[content].type}
                  data={courseModuleContents[content].file}
                  title={courseModuleContents[content].title}
                  description={courseModuleContents[content].description}
                  max_view_count={courseModuleContents[content].max_view_count}
                  downloadable={courseModuleContents[content].downloadable}
                  createdAt={courseModuleContents[content].createdAt}
                />
              </CustomDialog>
            </>
          )}
          <CustomDialog
            maxWidth={"md"}
            title="Add Section Content"
            buttonCaption="Add"
            open={showNewSectionContentModal}
            setOpen={setNewShowSectionContentModal}
          >
            <SectionContentForm
              courseModule={courseModules[section].id}
              action={0}
              type={newContentType}
              setOpen={setNewShowSectionContentModal}
            />
          </CustomDialog>
        </>
      )}
    </>
  );
}
