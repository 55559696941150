import { Grid, Hidden } from "@material-ui/core";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import React, { useContext, useState, useEffect } from "react";
import { CourseContext } from "../../context/CourseContext/CourseContext";
import { StudentContext } from "../../context/StudentContext/StudentContext";
import DataTable from "../../ui/DataTable/DataTable";
import SelectMenu from "../../ui/SelectMenu/SelectMenu";
import Paper from "@material-ui/core/Paper";
import { AuthContext } from "../../context/AuthContext/AuthContext";

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(1),
  },
  fixedHeight: {
    height: "100%",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
  },
}));

export default function StudentListTable() {
  const classes = useStyles();
  const {
    students,
    // initializing,
    studentsTableData,
    selectedCourses,
    setSelectedCourses,
    // addStudent,
    updateStudent,
    deleteStudent,
  } = useContext(StudentContext);
  const { updateUserAuthByAdmin } = useContext(AuthContext)
  const { courses } = useContext(CourseContext);

  const fixedHeightPaper = clsx(classes.paper, classes.fixedHeight);

  return (
    <Grid container spacing={3}>
      <Grid item xs={12} md={4} lg={4}>
        <Paper className={fixedHeightPaper}>
          <SelectMenu
            data={
              courses
                ? courses.map((item) => ({
                  value: item.id,
                  label: item.title,
                }))
                : []
            }
            label="Select Course"
            width={"100%"}
            value={selectedCourses}
            fullWidth={true}
            onChange={(e) => setSelectedCourses(e.target.value)}
            SelectProps={{ multiple: true }}
          />
        </Paper>
      </Grid>
      <Hidden xsDown>
        <Grid item xs={12} md={8} lg={8}></Grid>
      </Hidden>

      <Grid item xs={12} md={12} lg={12}>
        <DataTable
          grouping={true}
          exportButton={true}
          paging={true}
          title={`${courses &&
            courses
              .filter((val) => selectedCourses.includes(val.id))
              .map((val) => val.title)
            }`}
          columns={[
            { title: "Username", field: "username" },
            { title: "First name", field: "fname" },
            { title: "Last name", field: "lname" },
            // { title: "Email", field: "email" },
            // { title: "Dob", field: "dob" },
            // { title: "School", field: "school" },
            { title: "NIC", field: "nic" },
            { title: "Contact", field: "contact" },
            { title: "Gender", field: "gender" },
          ]}
          data={
            students
              ? students
              : []
          }
          editable={{
            // onRowAdd: (newRow) =>
            //   new Promise((resolve) => {
            //     resolve();
            //     setData((prevState) => {
            //       const newData = [...prevState];
            //       newData.push(newRow);
            //       return newData;
            //     });
            //     addStudent(newRow);
            //   }),
            // onRowUpdate: (newData, oldData) =>
            //   new Promise((resolve) => {
            //     resolve();
            //     if (oldData) {
            //       newData.gender = parseInt(newData.gender);
            //       setData((prevState) => {
            //         const updatedData = [...prevState];
            //         updatedData[updatedData.indexOf(oldData)] = newData;
            //         return updatedData;
            //       });
            //       console.log(newData, oldData);
            //       updateStudent(newData.id, newData);
            //     }
            //   }),
            onRowDelete: (oldData) =>
              new Promise((resolve) => {
                resolve();
                deleteStudent(oldData.id);
              }),
          }}
          actions={[
            {
              icon: "block",
              tooltip: "Disable User",
              onClick: (event, rowData) => {
                updateUserAuthByAdmin(rowData.id, { disabled: true });
              },
            },
            {
              icon: "vpn_key",
              tooltip: "Enable User",
              onClick: (event, rowData) => {
                updateUserAuthByAdmin(rowData.id, { disabled: false });
              },
            },
            {
              icon: "lock_open",
              tooltip: "Reset password",
              onClick: (event, rowData) => {
                updateUserAuthByAdmin(rowData.id, {
                  password: rowData.nic,
                });
              },
            },
            {
              icon: "phonelink_lock",
              tooltip: "Device Reset",
              onClick: (event, rowData) => {
                // console.log(rowData);
                updateStudent(rowData.id, { device_id: "" });
              },
            },
            {
              icon: "undo",
              tooltip: "Un enroll",
              onClick: (event, rowData) => {
                console.log(rowData);
                console.log(selectedCourses)
                let oldCourses = rowData.courses
                console.log(oldCourses)
                oldCourses && oldCourses.map((oldCourse) => selectedCourses.includes(oldCourse) && oldCourses.splice(oldCourses.indexOf(oldCourse), 1))
                console.log(oldCourses)
                updateStudent(rowData.id, { courses: oldCourses });
              },
            },
          ]}
        />
      </Grid>
    </Grid>
  );
}
