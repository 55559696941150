import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import { firebase, storage } from "../../firebase/firebaseConfig";

export const AppContext = React.createContext({});

export const AppProvider = ({ children }) => {
  const [online, setOnline] = useState(true);
  const [activity, setActivity] = useState(true)
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    window.addEventListener("offline", () =>
      enqueueSnackbar("Network disconnected", { variant: "error" })
    );
    window.addEventListener("blur", () => {
      setActivity(false)
    });

  }, []);

  const downloadFile = (file_name, file_type, file_url) => {
    console.log(file_type)
    var httpsReference = firebase.storage().refFromURL(file_url);
    httpsReference
      .getDownloadURL()
      .then(function (url) {
        var xhr = new XMLHttpRequest();
        xhr.responseType = "blob";
        xhr.onload = function (event) {
          var blob = xhr.response;
          // console.log(blob);
          const extension = file_type === 1 ? 'mp4' : file_type === 2 ? 'pdf' : file_type === 3 ? '.zip' : 'txt'
          var a = document.createElement("a");
          a.href = window.URL.createObjectURL(blob);
          a.download = `${file_name}.${extension}`;
          a.dispatchEvent(new MouseEvent("click"));
        };
        xhr.open("GET", url);
        xhr.send();
        enqueueSnackbar("File downloading", { variant: 'info' })
      })
      .catch(function (error) {
        enqueueSnackbar("File download error", { variant: "error" })
        // console.log(error);
      });
  }

  return (
    <AppContext.Provider
      value={{
        online,
        activity,
        setActivity,
        downloadFile
      }}
    >
      {children}
    </AppContext.Provider>
  );
};
