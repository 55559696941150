import Joi from "joi-browser";

let schema = Joi.object().keys({
  "first name": Joi.string().required(),
  "last name": Joi.string().required(),
  username: Joi.string().required(),
  contact: Joi.string().length(10).required(),
  nic: Joi.string().required(),
  password: Joi.string().min(6).max(15).required(),
  "confirm password": Joi.any()
    .valid(Joi.ref("password"))
    .required()
    .options({ language: { any: { allowOnly: "must match password" } } }),
  "institute code": Joi.string().required(),
});
schema = schema.optionalKeys(
  "first name",
  "last name",
  "username",
  "contact",
  "nic",
  "password",
  "confirm password",
  "institute code"
);
export default schema;
