import React, { useContext } from "react";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
// import CourseEnrolementsBarChart from "../../../../components/CourseEnrolementsBarChart";
// import StudentCountPieChart from "../../../../components/StudentCountPieChart";
import CoursesListTable from "../../../../components/CoursesListTable";
// import PaymentSummaryPieChart from "../../../../components/PaymentSummaryPieChart";
import AnnouncementList from "../../../../components/AnnouncementList";
// import { CourseProvider } from "../../../../context/CourseContext/CourseContext";
import { AuthContext } from "../../../../context/AuthContext/AuthContext";
import { AnnouncementProvider } from "../../../../context/AnnouncementContext/AnnouncementContext";
import PageTitle from "../../../../ui/PageTitle/PageTitle";

const useStyles = makeStyles((theme) => ({
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: "100vh",
    overflow: "auto",
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(1),
  },
  fixedHeight: {
    height: "100%",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
  },
}));

export default function Dashboard() {
  const classes = useStyles();
  const { role } = useContext(AuthContext);

  const fixedHeightPaper = clsx(classes.paper, classes.fixedHeight);

  return (
    <main className={classes.content}>
      <div className={classes.appBarSpacer} />
      {/* <CustomBreadcrumbs /> */}

      <PageTitle title={"Dashboard"} />
      <Container maxWidth="lg" className={classes.container}>
        <Grid container spacing={3}>
          {role === 0 && (
            <>
              {/* <Grid item xs={12} md={6} lg={6}>
                <Paper className={fixedHeightPaper}>
                  <StudentCountPieChart />
                </Paper>
              </Grid> */}

              <Grid item xs={12} md={12} lg={12}>
                {/* <Paper className={fixedHeightPaper}> */}
                <CoursesListTable />
                {/* </Paper> */}
              </Grid>
              {/* <Grid item xs={12} md={6} lg={6}>
                <Paper className={fixedHeightPaper}>
                  <CourseEnrolementsBarChart />
                </Paper>
              </Grid> */}
              {/* <Grid item xs={12} md={6} lg={6}>
                <Paper className={fixedHeightPaper}>
                  <PaymentSummaryPieChart />
                </Paper>
              </Grid> */}
            </>
          )}

          <Grid item xs={12} md={12} lg={12}>
            <Paper className={fixedHeightPaper}>
              <AnnouncementProvider>
                <AnnouncementList />
              </AnnouncementProvider>
            </Paper>
          </Grid>
        </Grid>
      </Container>
    </main>
  );
}
