import React, { useContext, useState } from "react";
import Grid from "@material-ui/core/Grid";
import {
  // BrowserRouter as Router,
  Switch,
  Route,
  useRouteMatch,
  useParams,
} from "react-router-dom";
import PageTitle from "../../../../../ui/PageTitle/PageTitle";
import IconButton from "@material-ui/core/IconButton";
import AddBoxIcon from "@material-ui/icons/AddBox";
import AllLessonsList from "../../../../../components/LessonsList";
import CourseContent from "./CourseContent/CourseContent";
import CustomDialog from "../../../../../ui/CustomDialog/CustomDialog";
import SectionForm from "../../../../../components/LessonsList/SectionForm";
import { AuthContext } from "../../../../../context/AuthContext/AuthContext";

export default function CourseDetails() {
  let { path } = useRouteMatch();
  const [showSectionModal, setShowSectionModal] = useState(false);
  const { course } = useParams();
  const { role } = useContext(AuthContext);

  return (
    <>
      {/* <Router> */}
      <Switch>
        <Route exact path={`${path}/`}>
          <>
            <Grid item xs={12} md={12} lg={12}>
              <PageTitle back={true} title={course ? course : ""}>
                {role === 0 && (
                  <IconButton
                    onClick={() => setShowSectionModal(true)}
                    edge="end"
                    aria-label="add"
                  >
                    <AddBoxIcon />
                  </IconButton>
                )}

                <CustomDialog
                  maxWidth={"md"}
                  title="Add Section"
                  buttonCaption="Add"
                  open={showSectionModal}
                  setOpen={setShowSectionModal}
                >
                  <SectionForm action={0} setOpen={setShowSectionModal} />
                </CustomDialog>
                {/* <IconButton edge="end" aria-label="delete">
                    <DeleteIcon />
                  </IconButton> */}
              </PageTitle>
            </Grid>
            <Grid item xs={12} md={12} lg={12}>
              <AllLessonsList />
            </Grid>
          </>
        </Route>
        <Route path={`${path}/:title`}>
          <CourseContent />
        </Route>
      </Switch>
      {/* </Router> */}
    </>
  );
}
