import React, { useContext, useEffect, useState } from "react";
// import { useHistory, useLocation } from "react-router-dom";
import app, { firebase } from "../../firebase/firebaseConfig";
import { AuthContext } from "../AuthContext/AuthContext";

export const AnnouncementContext = React.createContext({});

export const AnnouncementProvider = ({ children }) => {
  const [announcements, setAnnouncements] = useState(null);
  const [initializing, setInitializing] = useState(true);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);
  const { institute } = useContext(AuthContext);

  const addAnnouncement = (announcementObj, setOpen) => {
    app
      .firestore()
      .collection("announcements")
      .add({
        institute: institute,
        ...announcementObj,
        createdAt: firebase.firestore.Timestamp.fromDate(new Date()),
      })
      .then((docRef) => {
        setSuccess("Document written with ID: ", docRef.id);
        // console.log("Document written with ID: ", docRef.id);
        setOpen(false);
      })
      .catch((new_error) => {
        setError(new_error.message);
        console.error("Error adding document: ", new_error);
      });
  };

  const updateAnnouncement = (announcementId, announcementObj, setOpen) => {
    setLoading(true);
    app
      .firestore()
      .collection("announcements")
      .doc(announcementId)
      .update(announcementObj)
      .then(() => {
        setSuccess("Document successfully written!");
        // console.log("Document successfully written!");
        setOpen(false);
        setLoading(false);
      })
      .catch((new_error) => {
        setError("Error writing document: ", new_error);
        console.error("Error writing document: ", new_error);
        setLoading(false);
      });
  };

  const deleteAnnouncement = (announcementId) => {
    app
      .firestore()
      .collection("announcements")
      .doc(announcementId)
      .delete()
      .then(() => {
        setSuccess("Document successfully deleted!");
        // console.log("Document successfully deleted!");
      })
      .catch((new_error) => {
        setError("Error removing document: ", new_error);
        console.error("Error removing document: ", new_error);
      });
  };

  useEffect(() => {
    const getAnnouncements = () => {
      const unsubscribe = app
        .firestore()
        .collection("announcements")
        .where("institute", "==", institute)
        .orderBy("createdAt", "desc")
        .limitToLast(5)
        .onSnapshot((querySnapshot) => {
          const arr = [];
          querySnapshot.forEach((doc) => {
            arr.push({ id: doc.id, ...doc.data() });
          });
          setAnnouncements(arr);
          // console.log(arr);
          setInitializing(false);
        });
      return () => unsubscribe();
    };
    getAnnouncements();
  }, [institute]);

  return (
    <AnnouncementContext.Provider
      value={{
        announcements,
        error,
        initializing,
        success,
        loading,
        addAnnouncement,
        updateAnnouncement,
        deleteAnnouncement,
      }}
    >
      {children}
    </AnnouncementContext.Provider>
  );
};
