import React, { useContext, useState } from "react";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
// import { useHistory } from "react-router-dom";
import SelectMenu from "../../ui/SelectMenu/SelectMenu";
import TextInput from "../../ui/TextInput/TextInput";
import ImageUpload from "../../ui/ImageUpload/ImageUpload";
import CustomTimePicker from "../../ui/CustomTimePicker/CustomTimePicker";
import { Days } from "../../constants/Days";
import { CourseContext } from "../../context/CourseContext/CourseContext";
import { CircularProgress } from "@material-ui/core";
import { CourseCategories } from "../../constants/CourseCategories";
import { Mediums } from "../../constants/Mediums";
// import { Typography } from "@material-ui/core";
// import CloudUploadIcon from "@material-ui/icons/CloudUpload";

const useStyles = makeStyles((theme) => ({
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

export default function AddCourseForm(props) {
  const classes = useStyles();

  const { addCourse, updateCourse, loading } = useContext(CourseContext);

  const { action, setOpen, course } = props;
  const [imageFiles, setImageFiles] = useState(null);

  const [image, setImage] = useState(
    action === 1 && course && course.image ? course.image : ""
  );

  const [title, setTitle] = useState(
    action === 1 && course && course.title ? course.title : ""
  );
  const [description, setDescription] = useState(
    action === 1 && course && course.description ? course.description : ""
  );
  const [courseCategory, setCourseCategory] = useState(
    action === 1 && course && course.category ? course.category : 1
  );
  const [medium, setMedium] = useState(
    action === 1 && course && course.medium ? course.medium : 1
  );
  const [day, setDay] = useState(
    action === 1 && course ? course.day : new Date().getDay()
  );

  const dateNow = new Date();
  const [startTime, setStartTime] = useState(
    action === 1 && course && course.startTime
      ? new Date(course.startTime.toDate())
      : dateNow
  );

  const [endTime, setEndTime] = useState(
    action === 1 && course && course.endTime
      ? new Date(course.endTime.toDate())
      : dateNow
  );

  let OnSubmit = (e) => {
    e.preventDefault();
    if (title !== "" && description !== "") {
      const obj = {
        title: title,
        description: description,
        startTime: startTime,
        endTime: endTime,
        medium: medium,
        courseCategory: courseCategory,
        day: day,
        image: image,
        imageFiles: imageFiles,
      };
      // console.log(obj);
      if (action === 1) {
        updateCourse(course.id, obj, setOpen);
      } else {
        addCourse(obj, setOpen);
      }
    }
  };

  return (
    <form
      onSubmit={OnSubmit}
      className={classes.form}
      noValidate
    >
      <Grid container spacing={2}>
        <Grid item container xs={12} md={12} lg={12}>
          <Grid item xs={12} md={3} lg={3}>
            <ImageUpload
              setImageFiles={setImageFiles}
              image={image}
              width={200}
              height={200}
              maxSize={5000000}
            />
          </Grid>
          <Grid item container xs={12} md={9} lg={9}>
            <Grid item xs={12} md={12} lg={12}>
              <TextInput
                value={title}
                onChange={(e) => setTitle(e.target.value)}
                label="Course Name"
                name="cname"
                helperText={"Ex: Computer Science"}
                autoFocus={true}
              />
            </Grid>
            <Grid item xs={12} md={12} lg={12}>
              <TextInput
                value={description}
                onChange={(e) => setDescription(e.target.value)}
                label="Course Description"
                name="cdesription"
                helperText={"Ex: Breif introduction"}
                multiline={true}
                rows={3}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={12} md={12} lg={12}></Grid>
        </Grid>
      </Grid>

      <Grid container spacing={2}>
        <Grid item xs={6} md={6} lg={6}>
          <SelectMenu
            data={CourseCategories.map((val, index) => ({
              value: index + 1,
              label: val,
            }))}
            label="Select Category"
            width={"100%"}
            value={courseCategory}
            fullWidth={true}
            onChange={(e) => {
              setCourseCategory(e.target.value);
            }}
            variant="outlined"
          />
        </Grid>
        <Grid item xs={6} md={6} lg={6}>
          <SelectMenu
            data={Mediums.map((val, index) => ({
              value: index + 1,
              label: val,
            }))}
            label="Select Medium"
            width={"100%"}
            value={medium}
            fullWidth={true}
            onChange={(e) => {
              setMedium(e.target.value);
            }}
            variant="outlined"
          />
        </Grid>
        <Grid item xs={4} md={4} lg={4}>
          <SelectMenu
            data={Days.map((val, index) => ({
              value: index + 1,
              label: val,
            }))}
            label="Select Day"
            width={"100%"}
            value={day}
            fullWidth={true}
            onChange={(e) => {
              setDay(e.target.value);
            }}
            variant="outlined"
          />
        </Grid>
        <Grid item xs={4} md={4} lg={4}>
          <CustomTimePicker
            value={startTime}
            onChange={(e) => setStartTime(e)}
            size="large"
            label="Start Time"
          />
        </Grid>
        <Grid item xs={4} md={4} lg={4}>
          <CustomTimePicker
            value={endTime}
            onChange={(e) => setEndTime(e)}
            size="large"
            label="End Time"
            maxDate={dateNow}
          />
        </Grid>
      </Grid>
      <Button
        startIcon={loading && <CircularProgress size={20} />}
        fullWidth
        variant="contained"
        color="primary"
        className={classes.submit}
        // onClick={OnSubmit}
        type="submit"
      >
        {action === 1 ? "Update Course" : "Add Course"}
      </Button>
    </form>
  );
}
