import { Grid, Hidden } from "@material-ui/core";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import React, { useContext, useState, useEffect } from "react";
import DataTable from "../../ui/DataTable/DataTable";
import SelectMenu from "../../ui/SelectMenu/SelectMenu";
import Paper from "@material-ui/core/Paper";
import { AuthContext } from "../../context/AuthContext/AuthContext";
import { AdministratorContext } from "../../context/AdministratorContext/AdministratorContext";
import { InstituteContext } from "../../context/InstituteContext/InstituteContext";
import { toTitleCase } from "../../utils/utils";
import CustomDialog from "../../ui/CustomDialog/CustomDialog";
import AddInstituteForm from "../AddInstituteForm";
import AdministratorForm from "../AdministratorForm";

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(1),
  },
  fixedHeight: {
    height: "100%",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
  },
}));

export default function AdministratorTable() {

  const classes = useStyles();

  const {
    loading,
    administrators,
    selectedInstitute,
    setSelectedInstitute,
    setSelectedAdministrator
  } = useContext(AdministratorContext);

  const {
    institutes,
  } = useContext(InstituteContext);

  const { updateUserAuthByAdmin } = useContext(AuthContext)

  const fixedHeightPaper = clsx(classes.paper, classes.fixedHeight);

  const [showAddAdministratorModal, setShowAddAdministratorModal] = useState(false)
  const [edit, setEdit] = useState(false)

  const handleAdministratorModel = (edit, rowData) => {
    console.log(rowData, administrators, edit, administrators.find(administrator => administrator.id === rowData.id))
    setEdit(edit)
    edit && setSelectedAdministrator(administrators.find(administrator => administrator.id === rowData.id))
    setShowAddAdministratorModal(true)
  }

  return (
    <Grid container spacing={3}>
      <Grid item xs={12} md={4} lg={4}>
        <Paper className={fixedHeightPaper}>
          <SelectMenu
            data={
              institutes
                ? institutes.map((item) => ({
                  value: item.id,
                  label: toTitleCase(item.name),
                }))
                : []
            }
            label="Select Institute"
            width={"100%"}
            value={selectedInstitute && selectedInstitute.id || ""}
            fullWidth={true}
            onChange={(e) => setSelectedInstitute(institutes.find(institute => institute.id === e.target.value))}
          />
        </Paper>
      </Grid>
      <Hidden xsDown>
        <Grid item xs={12} md={8} lg={8}></Grid>
      </Hidden>

      <Grid item xs={12} md={12} lg={12}>
        <DataTable
          grouping={true}
          paging={true}
          title={`${selectedInstitute &&
            toTitleCase(selectedInstitute.name) || ""}`}
          columns={[
            { title: "Username", field: "username" },
          ]}
          data={
            administrators || []
          }
          editable={{
            onRowDelete: (oldData) =>
              new Promise((resolve) => {
                resolve();
                // deleteStudent(oldData.id);
              }),
          }}
          actions={[
            selectedInstitute && {
              icon: "add_box",
              tooltip: "Add Administrator",
              isFreeAction: true,
              onClick: (event, rowData) => handleAdministratorModel(false, rowData),
            },
            {
              icon: "edit",
              tooltip: "Edit Administrator",

              onClick: (event, rowData) => handleAdministratorModel(true, rowData),
            },
            {
              icon: "block",
              tooltip: "Disable User",
              onClick: (event, rowData) => {
                updateUserAuthByAdmin(rowData.id, { disabled: true });
              },
            },
            {
              icon: "vpn_key",
              tooltip: "Enable User",
              onClick: (event, rowData) => {
                updateUserAuthByAdmin(rowData.id, { disabled: false });
              },
            },
            {
              icon: "lock_open",
              tooltip: "Reset password",
              onClick: (event, rowData) => {
                // console.log(rowData.id, {
                //   password: rowData.username + "@password",
                // });
                updateUserAuthByAdmin(rowData.id, {
                  password: rowData.username + "@password",
                });
              },
            }
          ]}
        />
        <CustomDialog
          maxWidth={"md"}
          title={edit ? "Edit Administrator" : "Add Administrator"}
          buttonCaption="Add"
          open={showAddAdministratorModal}
          setOpen={setShowAddAdministratorModal}
        >

          <AdministratorForm setOpen={setShowAddAdministratorModal} action={edit ? 1 : 0} />

        </CustomDialog>
      </Grid>
    </Grid>
  );
}
