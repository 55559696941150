import React, { useContext, useEffect, useState } from "react";
import { CourseContext } from "../../context/CourseContext/CourseContext";
import CustomDialog from "../../ui/CustomDialog/CustomDialog";
import DataTable from "../../ui/DataTable/DataTable";
import AddCourseForm from "../AddCourseForm";

export default function CoursesListTable() {
  const {
    courses,
    coursesTableData,
    // addCourse,
    // updateCourse,
    deleteCourse,
  } = useContext(CourseContext);
  const [data, setData] = useState(null);
  const [edit, setEdit] = useState(false);
  const [selectedCourse, setSelectedCourse] = useState(null);
  const [showCourseModal, setShowCourseModal] = useState(false);

  useEffect(() => {
    setData(coursesTableData);
  }, [coursesTableData]);

  const handleShowEditCourse = (rowData) => {
    setEdit(true);
    setShowCourseModal(true);
    setSelectedCourse(courses[rowData.tableData.id]);
  };
  const handleShowAddCourse = (rowData) => {
    setEdit(false);
    setShowCourseModal(true);
  };

  // console.log("console.log(coursesTableData)", coursesTableData);
  return (
    <>
      <DataTable
        title="COURSES"
        columns={[
          { title: "Title", field: "title" },
          // { title: "Description", field: "description" },
          { title: "Day", field: "day" },
          { title: "Start Time", field: "startTime" },
          { title: "End Time", field: "endTime" },
        ]}
        data={data ? data : []}
        editable={{
          // onRowAdd: (newRow) =>
          //   new Promise((resolve) => {
          //     resolve();
          //     // setShowAddCourseModal(true);
          //     addCourse(newRow);
          //   }),
          // onRowUpdate: (newData, oldData) =>
          //   new Promise((resolve) => {
          //     resolve();
          //     if (oldData) {
          //       // setData((prevState) => {
          //       //   const updatedData = [...prevState];
          //       //   updatedData[updatedData.indexOf(oldData)] = newData;
          //       //   return updatedData;
          //       // });
          //       console.log(newData, oldData);
          //       updateCourse(newData.id, newData);
          //     }
          //   }),
          onRowDelete: (oldData) =>
            new Promise((resolve) => {
              resolve();
              // setData((prevState) => {
              //   const removedData = [...prevState];
              //   removedData.splice(removedData.indexOf(oldData), 1);
              //   return removedData;
              // });
              deleteCourse(oldData.id);
            }),
        }}
        actions={[
          {
            icon: "add_box",
            tooltip: "Add Course",
            isFreeAction: true,
            onClick: (event, rowData) => handleShowAddCourse(rowData),
          },
          {
            icon: "edit",
            tooltip: "Edit Course",

            onClick: (event, rowData) => handleShowEditCourse(rowData),
          },
        ]}
      />

      <CustomDialog
        maxWidth={"md"}
        title={edit ? "Edit Course" : "Add Course"}
        buttonCaption="Add"
        open={showCourseModal}
        setOpen={setShowCourseModal}
      >
        {edit ? (
          <AddCourseForm
            setOpen={setShowCourseModal}
            course={selectedCourse}
            action={1}
          />
        ) : (
          <AddCourseForm setOpen={setShowCourseModal} action={edit ? 1 : 0} />
        )}
      </CustomDialog>
      {/* <CustomDialog
        maxWidth={"md"}
        title="Edit Course"
        buttonCaption="Edit"
        open={showEditCourseModal}
        setOpen={setShowEditCourseModal}
      >
        <AddCourseForm
          setOpen={setShowEditCourseModal}
          course={selectedCourse}
          action={1}
        />
      </CustomDialog> */}
    </>
  );
}
