import React, { useContext, useEffect, useState } from "react";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import DataTable from "../../ui/DataTable/DataTable";
import SelectMenu from "../../ui/SelectMenu/SelectMenu";
import { Button } from "@material-ui/core";
// import Hidden from "@material-ui/core/Hidden";
import { CourseContext } from "../../context/CourseContext/CourseContext";
import SearchBar from "../../ui/SearchBar/SearchBar";
import { StudentContext } from "../../context/StudentContext/StudentContext";
import { Years } from "../../constants/Years";
import { Months } from "../../constants/Months";
import { PaymentContext } from "../../context/PaymentContext/PaymentContext";
import { InstituteContext } from "../../context/InstituteContext/InstituteContext";
import { toTitleCase } from "../../utils/utils";
import { UsageContext } from "../../context/UsageContext/UsageContext";

const useStyles = makeStyles((theme) => ({
    paper: {
        padding: theme.spacing(1),
    },
    fixedHeight: {
        height: "100%",
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
    },
}));

export default function UsageFilter() {
    const classes = useStyles();

    const { institutes } = useContext(InstituteContext);
    const { courses } = useContext(CourseContext);

    const {
        selectedInstitute,
        selectedCourse,
        selectedMonth,
        setSelectedCourse,
        setSelectedInstitute,
        setSelectedMonth
    } = useContext(UsageContext);

    const fixedHeightPaper = clsx(classes.paper, classes.fixedHeight);

    return (
        <Grid container spacing={3}>
            <Grid item xs={12} md={4} lg={4}>
                <Paper className={fixedHeightPaper}>
                    <SelectMenu
                        data={
                            institutes
                                ? institutes.map((item) => ({
                                    value: item.id,
                                    label: toTitleCase(item.name),
                                }))
                                : []
                        }
                        label="Select Institute"
                        width={"100%"}
                        value={selectedInstitute && selectedInstitute.id || ""}
                        fullWidth={true}
                        onChange={(e) => setSelectedInstitute(institutes.find(institute => institute.id === e.target.value))}
                    />
                </Paper>
            </Grid>
            <Grid item xs={12} md={4} lg={4}>
                <Paper className={fixedHeightPaper}>
                    <SelectMenu
                        data={
                            courses
                                ? courses.map((item) => ({
                                    value: item.id,
                                    label: item.title,
                                }))
                                : []
                        }
                        label="Select Course"
                        width={"100%"}
                        value={selectedCourse ? selectedCourse : ""}
                        fullWidth={true}
                        // SelectProps={{ multiple: true }}
                        onChange={(e) => {
                            setSelectedCourse(e.target.value);
                        }}
                    />
                </Paper>
            </Grid>
            {/* <Hidden xsDown>
          <Grid item xs={12} md={4} lg={4}></Grid>
        </Hidden> */}


            <Grid item xs={12} md={4} lg={4}>
                <Paper className={fixedHeightPaper}>
                    <SelectMenu
                        data={Months.map((val, index) => ({
                            value: index + 1,
                            label: val,
                        }))}
                        label="Select Month"
                        width={"100%"}
                        value={selectedMonth}
                        fullWidth={true}
                        onChange={(e) => {
                            setSelectedMonth(e.target.value);
                        }}
                    />
                </Paper>
            </Grid>
        </Grid>
    );
}
