import React, { useContext, useEffect, useState } from "react";
// import { useHistory, useLocation } from "react-router-dom";
import app, { firebase } from "../../firebase/firebaseConfig";
import { AuthContext } from "../AuthContext/AuthContext";
import { CourseContext } from "../CourseContext/CourseContext";
import { useSnackbar } from "notistack";
import { Days } from "../../constants/Days";
import { Genders } from "../../constants/Genders";
import { InstituteContext } from "../InstituteContext/InstituteContext";

export const AdministratorContext = React.createContext({});

export const AdministratorProvider = ({ children }) => {
  const { updateUserAuthByAdmin } = useContext(AuthContext);

  const [administrators, setAdministrators] = useState(null);
  const [selectedInstitute, setSelectedInstitute] = useState(null);
  const [selectedAdministrator, setSelectedAdministrator] = useState(null);

  const [initializing, setInitializing] = useState(null);
  const [loading, setLoading] = useState(false);

  const { enqueueSnackbar } = useSnackbar();

  const addAdministrator = (administratorObj) => {

  };

  const updateAdministrator = (administratorId, administratorObj) => {

  };

  const deleteAdministrator = (administratorId) => {
    // delete by admin
    app
      .functions()
      .httpsCallable("api/deleteUser")({ uid: administratorId })
      .then((result) => {
        console.log("Successfully deleted user", administratorId);
        enqueueSnackbar("Successfully deleted user", { variant: "success" });
      })
      .catch((e) => {
        console.log("Error deleting user:", e);
        enqueueSnackbar(e.message, { variant: "error" });
      });
  };

  useEffect(() => {
    const getAdministratorsByInstitute = () => {
      setInitializing(true);
      console.log(selectedInstitute)
      app
        .firestore()
        .collection("users")
        .where("institute", "==", selectedInstitute && selectedInstitute.id || "1")
        .where("role", "==", 0)
        .get()
        .then((querySnapshot) => {
          const arr = [];
          querySnapshot.forEach((doc) => {
            arr.push({ id: doc.id, ...doc.data() });
          });
          setAdministrators(arr)
          setInitializing(false);
        })
        .catch((e) => {
          setInitializing(false)
          enqueueSnackbar(e.message, { variant: "error" });
        });
    };
    getAdministratorsByInstitute();
  }, [selectedInstitute]);

  return (
    <AdministratorContext.Provider
      value={{
        loading,
        administrators,
        selectedInstitute,
        setSelectedInstitute,
        selectedAdministrator,
        setSelectedAdministrator,
        addAdministrator,
        updateAdministrator,
        deleteAdministrator
      }}
    >
      {children}
    </AdministratorContext.Provider>
  );
};
