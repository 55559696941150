import React, { useContext, useEffect, useState } from "react";
import { CourseContext } from "../../context/CourseContext/CourseContext";
import { InstituteContext } from "../../context/InstituteContext/InstituteContext";
import CustomDialog from "../../ui/CustomDialog/CustomDialog";
import DataTable from "../../ui/DataTable/DataTable";
import AddCourseForm from "../AddCourseForm";
import AddInstituteForm from "../AddInstituteForm";

export default function InstitutesTable() {

  const {
    institutes,
    setSelectedInstitute,
    selectedInstitute,
    // addCourse,
    // updateCourse,
    deleteInstitute,
  } = useContext(InstituteContext);

  const [edit, setEdit] = useState(false);
  const [showInstituteModal, setShowInstituteModal] = useState(false);

  const handleShowEditInstitute = (rowData) => {
    setEdit(true);
    setSelectedInstitute(institutes[rowData.tableData.id])
    setShowInstituteModal(true);
  };

  const handleShowAddInstitute = (rowData) => {
    setEdit(false);
    setShowInstituteModal(true);
  };

  return (
    <>
      <DataTable
        title="Institutes"
        columns={[
          { title: "Code", field: "iid" },
          { title: "Name", field: "name" },
        ]}
        data={institutes ? institutes : []}
        editable={{
          // onRowAdd: (newRow) =>
          //   new Promise((resolve) => {
          //     resolve();
          //     // setShowAddCourseModal(true);
          //     addCourse(newRow);
          //   }),
          // onRowUpdate: (newData, oldData) =>
          //   new Promise((resolve) => {
          //     resolve();
          //     if (oldData) {
          //       // setData((prevState) => {
          //       //   const updatedData = [...prevState];
          //       //   updatedData[updatedData.indexOf(oldData)] = newData;
          //       //   return updatedData;
          //       // });
          //       console.log(newData, oldData);
          //       updateCourse(newData.id, newData);
          //     }
          //   }),
          onRowDelete: (oldData) =>
            new Promise((resolve) => {
              resolve();
              // deleteInstitute(oldData.id);
            }),
        }}
        actions={[
          {
            icon: "add_box",
            tooltip: "Add Institute",
            isFreeAction: true,
            onClick: (event, rowData) => handleShowAddInstitute(rowData),
          },
          {
            icon: "edit",
            tooltip: "Edit Institute",

            onClick: (event, rowData) => handleShowEditInstitute(rowData),
          },
        ]}
      />

      <CustomDialog
        maxWidth={"md"}
        title={edit ? "Edit Institute" : "Add Institute"}
        buttonCaption="Add"
        open={showInstituteModal}
        setOpen={setShowInstituteModal}
      >
        {edit ? (
          <AddInstituteForm
            setOpen={setShowInstituteModal}
            action={1}
          />
        ) : (
          <AddInstituteForm setOpen={setShowInstituteModal} action={edit ? 1 : 0} />
        )}
      </CustomDialog>
    </>
  );
}
