import React, { useContext, useState } from "react";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import PageTitle from "../../../../../../ui/PageTitle/PageTitle";
import ReactPlayer from "react-player";
import { useLocation, useParams } from "react-router-dom";
// import Iframe from "react-iframe";
import { convertFromRaw, Editor, EditorState } from "draft-js";
// import FileViewer from "react-file-viewer";
// import DocViewer from "react-doc-viewer";
import { IconButton } from "@material-ui/core";
import { CloudDownload } from "@material-ui/icons";
import { Document, Page, pdfjs } from "react-pdf";
import Pagination from "@material-ui/lab/Pagination";
import { useResizeDetector } from "react-resize-detector";
import { AppContext } from "../../../../../../context/AppContext/AppContext";
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const useStyles = makeStyles((theme) => ({
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: "100vh",
    overflow: "auto",
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(1),
  },
  fixedHeight: {
    height: "100%",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
  },
}));

export default function CourseContent(props) {
  const classes = useStyles();

  const fixedHeightPaper = clsx(classes.paper, classes.fixedHeight);
  const { title } = useParams();
  const { state } = useLocation();
  const { downloadFile } = useContext(AppContext)
  let { type, file, remaining, downloadable } = state.params;
  type = parseInt(type);

  const [editorState, setEditorState] = useState(() =>
    type === 0
      ? EditorState.createWithContent(convertFromRaw(file))
      : EditorState.createEmpty()
  );

  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);

  const { width, ref } = useResizeDetector();

  const onDocumentLoadSuccess = (np) => {
    setNumPages(np._pdfInfo.numPages);
  };

  const handleDownload = (file_name, file_type, url) => {
    downloadFile(file_name, file_type, url)
  }
  return (
    <>
      <Grid item xs={12} md={12} lg={12}>
        <PageTitle
          back={true}
          title={
            title
              ? remaining
                ? title + " (Views remaining " + (remaining - 1) + ")"
                : title
              : ""
          }
        >
          {downloadable && <IconButton
            style={{ marginRight: 10 }}
            onClick={() => handleDownload(title, type, file)}
            edge="end"
            aria-label="back"
          >
            <CloudDownload />
          </IconButton>}

        </PageTitle>
      </Grid>
      <Grid item xs={12} md={12} lg={12}>
        <Paper className={fixedHeightPaper}>
          {type === 0 ? (
            <Editor
              readOnly={true}
              editorState={editorState}
              onChange={(newState) => setEditorState(newState)}
            />
          ) : type === 1 ? (
            // <video
            //   onContextMenu={(e) => e.preventDefault()}
            //   width="100%"
            //   height="500"
            //   controls
            //   controlsList="nodownload"
            //   playsInline
            // >
            //   <source src={file} type="video/mp4" />
            // </video>
            <ReactPlayer
              config={{
                file: {
                  attributes: {
                    onContextMenu: (e) => e.preventDefault(),
                    controlsList: "nodownload",
                  },
                },
              }}
              playsinline={true}
              controls={true}
              light={false}
              width={"100%"}
              height={460}
              url={file}
              onProgress={(obj) => console.log(obj)}
            />
          ) : (

            <>
              {file && (
                // <Iframe
                //   url={"//docs.google.com/gview?url=" + file + "&embedded=true"}
                //   width="100%"
                //   height={window.innerHeight}
                //   id="myId"
                //   frameBorder={0}
                //   className="myClassname"
                //   display="block"
                //   position="relative"
                //   loading="lazy"
                //   sandbox="allow-scripts allow-same-origin"
                //   allowFullScreen={true}
                //   allow="fullscreen"
                // />
                // <FileViewer
                //   fileType={"pdf"}
                //   filePath={file}
                //   onError={(e) => console.log(e)}
                // />
                // <DocViewer documents={[{ uri: file }]} />
                <div ref={ref} style={{ width: "100%" }} id="pdfviewer">
                  <Grid item xs={12} md={12} lg={12}>
                    <Document
                      file={file}
                      noData="No PDF file specified."
                      onLoadSuccess={onDocumentLoadSuccess}
                      onLoadError={(e) => console.log(e)}
                    >
                      <Page width={width} pageNumber={pageNumber} />
                    </Document>
                  </Grid>

                  <Grid item container xs={12} md={12} lg={12} justify="center">
                    <Pagination
                      count={numPages}
                      page={pageNumber}
                      onChange={(event, value) => setPageNumber(value)}
                      color="primary"
                    />
                  </Grid>
                </div>
              )}
            </>
          )}
        </Paper>
      </Grid>
    </>
  );
}
