import React, { useContext, useState } from "react";
import { Grid, Button, CircularProgress } from "@material-ui/core";
import { convertFromRaw, convertToRaw, EditorState } from "draft-js";
import TextEditor from "../../../ui/TextEditor/TextEditor";
import TextInput from "../../../ui/TextInput/TextInput";
import { AnnouncementContext } from "../../../context/AnnouncementContext/AnnouncementContext";

export default function AnnouncementForm(props) {
  const { addAnnouncement, updateAnnouncement, loading } = useContext(
    AnnouncementContext
  );
  const { id, title, description, action, setOpen } = props;

  const [newTitle, setNewTitle] = useState(title ? title : "");
  const [content, setContent] = useState(
    description
      ? EditorState.createWithContent(convertFromRaw(description))
      : EditorState.createEmpty()
  );

  return (
    <Grid container spacing={1}>
      <Grid item lg={12} md={12} xs={12}>
        <TextInput
          label="Title"
          name="title"
          value={newTitle}
          onChange={(e) => setNewTitle(e.target.value)}
          helperText="The title cannot be empty"
        />
      </Grid>
      <Grid item lg={12} md={12} xs={12}>
        <TextEditor
          value={content}
          onChange={(editor_data) => {
            setContent(editor_data);
            // const ccontentState = editor_data.getCurrentContent();
            // console.log(JSON.stringify(convertToRaw(ccontentState)));
          }}
          placeholder="  Enter description"
        />
      </Grid>
      <Grid item lg={12} md={12} xs={12}>
        {action === 0 ? (
          <Button
            fullWidth
            variant="contained"
            color="primary"
            onClick={() => {
              newTitle &&
                addAnnouncement(
                  {
                    title: newTitle,
                    description: convertToRaw(content.getCurrentContent()),
                  },
                  setOpen
                );
            }}
          >
            Add
          </Button>
        ) : (
          <Button
            disabled={loading}
            startIcon={loading && <CircularProgress size={20} />}
            fullWidth
            variant="contained"
            color="primary"
            onClick={() => {
              newTitle &&
                updateAnnouncement(
                  id,
                  {
                    title: newTitle,
                    description: convertToRaw(content.getCurrentContent()),
                  },
                  setOpen
                );
            }}
          >
            Update
          </Button>
        )}
      </Grid>
    </Grid>
  );
}
