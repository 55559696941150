import Joi from "joi-browser";

export function toTitleCase(str) {
  return str.replace(/\w\S*/g, function (txt) {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
  });
}

export function validate(formObject, schema) {
  const valid = Joi.validate(formObject, schema, {
    abortEarly: false,
  });
  const newErrorObject = {};
  if (valid.error) {
    valid.error.details.forEach((err) => {
      newErrorObject[err.path.join(".")] = err.message;
    });
  }
  console.log(newErrorObject);
  return newErrorObject;
}

export const browserDetect = () => {
  if ((navigator.userAgent.indexOf("Opera") || navigator.userAgent.indexOf('OPR')) !== -1) {
    console.log('Opera');
    // alert('Opera')
    return true
  }
  else if (navigator.userAgent.indexOf("Chrome") !== -1) {
    console.log('Chrome');
    // alert('Chrome')
    return true
  }
  else if (navigator.userAgent.indexOf("Safari") !== -1) {
    console.log('Safari');
    // alert('Safari');
    return true
  }
  else if (navigator.userAgent.indexOf("Firefox") !== -1) {
    console.log('Firefox');
    // alert('Firefox');
    return true
  }
  else if ((navigator.userAgent.indexOf("MSIE") !== -1) || (!!document.documentMode == true)) //IF IE > 10
  {
    console.log('IE');
    // alert('IE');
    return true
  }
  else {
    console.log('unknown');
    return false
  }
}
