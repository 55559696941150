import React, { useContext, useState } from "react";
import Button from "@material-ui/core/Button";
// import FormControlLabel from "@material-ui/core/FormControlLabel";
// import Checkbox from "@material-ui/core/Checkbox";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
// import { useHistory } from "react-router-dom";
import TextInput from "../../ui/TextInput/TextInput";
import { AuthContext } from "../../context/AuthContext/AuthContext";
import { IconButton, InputAdornment } from "@material-ui/core";
import { Visibility, VisibilityOff } from "@material-ui/icons";
// import { Typography } from "@material-ui/core";
import { validate } from "../../utils/utils";

import schema from "./signinValidateScheme";

const useStyles = makeStyles((theme) => ({
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

export default function SignInForm() {
  const classes = useStyles();
  // const history = useHistory();
  const [uname, setUname] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [error, setError] = useState({});
  const { signIn, initializing, signOut } = useContext(AuthContext);

  const onSubmit = (e) => {
    e.preventDefault();
    const newErrorObject = validate(
      { username: uname, password: password },
      schema
    );
    if (Object.keys(newErrorObject).length > 0) {
      setError(newErrorObject);
      return;
    }

    signIn(`${uname}.com`, password);
  };

  return (
    <form onSubmit={onSubmit} className={classes.form}>
      <Grid container spacing={2}>
        <Grid item xs={12} md={12} lg={12}>
          <TextInput
            label="Username"
            name="email"
            autoFocus={true}
            error={error.username}
            size="large"
            value={uname}
            onFocus={() => delete error["username"]}
            onBlur={() =>
              setError({ ...error, ...validate({ username: uname }, schema) })
            }
            helperText={error.username}
            onChange={(e) => setUname(e.target.value)}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">.com</InputAdornment>
              ),
            }}
          />
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={12} md={12} lg={12}>
          <TextInput
            label="Password"
            name="password"
            size="large"
            error={error.password}
            onFocus={() => {
              delete error["password"];
              // console.log(error);
            }}
            onBlur={() =>
              setError({
                ...error,
                ...validate({ password: password }, schema),
              })
            }
            helperText={error.password}
            type={showPassword ? "text" : "password"}
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onMouseDown={() => setShowPassword(true)}
                    onMouseUp={() => setShowPassword(false)}
                    edge="end"
                  >
                    {showPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </Grid>
      </Grid>

      {/* <Grid container spacing={2}>
        <Grid item xs={12} md={12} lg={12}>
          <Typography variant="body1" color="secondary">
            {error}
          </Typography>
        </Grid>
      </Grid> */}

      {/* <FormControlLabel
        control={<Checkbox value="remember" color="primary" />}
        label="Remember me"
      /> */}
      <Button
        disabled={initializing}
        fullWidth
        variant="contained"
        color="primary"
        className={classes.submit}
        type="submit"
      // onClick={() => {
      //   signIn(uname + ".dayalherath@gmail.com", password);
      // }}
      >
        Login
      </Button>
      {/* <Button
        fullWidth
        variant="contained"
        color="primary"
        className={classes.submit}
        onClick={signOut}
      >
        sign out
      </Button> */}
    </form>
  );
}
