import React, { useContext, useState } from "react";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
// import { useHistory } from "react-router-dom";
import SelectMenu from "../../ui/SelectMenu/SelectMenu";
import TextInput from "../../ui/TextInput/TextInput";
import ImageUpload from "../../ui/ImageUpload/ImageUpload";
import CustomTimePicker from "../../ui/CustomTimePicker/CustomTimePicker";
import { Days } from "../../constants/Days";
import { InstituteContext } from "../../context/InstituteContext/InstituteContext";
import { CircularProgress } from "@material-ui/core";
import { CourseCategories } from "../../constants/CourseCategories";
import { Mediums } from "../../constants/Mediums";
import { CourseContext } from "../../context/CourseContext/CourseContext";
// import { Typography } from "@material-ui/core";
// import CloudUploadIcon from "@material-ui/icons/CloudUpload";

const useStyles = makeStyles((theme) => ({
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

export default function AddInstituteForm(props) {
  const classes = useStyles();

  const { addInstitute, updateInstitute, uploadFileAsync, selectedInstitute, loading } = useContext(InstituteContext);

  const { action, setOpen } = props;

  const [imageFiles, setImageFiles] = useState(null);

  const [image, setImage] = useState(
    action === 1 && selectedInstitute && selectedInstitute.image ? selectedInstitute.image : ""
  );

  const [name, setName] = useState(
    action === 1 && selectedInstitute && selectedInstitute.name ? selectedInstitute.name : ""
  );
  const [iid, setIID] = useState(
    action === 1 && selectedInstitute && selectedInstitute.iid ? selectedInstitute.iid : ""
  );

  const [id, setID] = useState(
    action === 1 && selectedInstitute && selectedInstitute.id ? selectedInstitute.id : ""
  );


  let OnSubmit = async (e) => {
    e.preventDefault();
    if (action === 1) {
      let url = imageFiles && await uploadFileAsync(selectedInstitute.id, imageFiles[0], "details")
      setImage(url)
      await updateInstitute(selectedInstitute.id, { image: url });
    } else {
      const response = await addInstitute({ name, iid, image, valid: false });
      let url = imageFiles && await uploadFileAsync(response.id, imageFiles[0], "details")
      setImage(url)
      await updateInstitute(response.id, { image: url });
    }
    setOpen(false)
  };

  return (
    <form
      onSubmit={OnSubmit}
      className={classes.form}
      noValidate
    >
      <Grid container spacing={2}>
        <Grid item container xs={12} md={12} lg={12}>
          <Grid item xs={12} md={3} lg={3}>
            <ImageUpload
              setImageFiles={setImageFiles}
              image={image}
              width={200}
              height={200}
              maxSize={5000000}
            />
          </Grid>
          <Grid item container xs={12} md={9} lg={9}>
            {action === 1 &&
              <Grid item xs={12} md={12} lg={12}>
                <TextInput
                  value={id}
                  onChange={(e) => setID(e.target.value)}
                  label="Institute ID"
                  name="id"
                  disabled={true}
                />
              </Grid>}
            <Grid item xs={12} md={12} lg={12}>
              <TextInput
                value={name}
                onChange={(e) => setName(e.target.value)}
                label="Institute Name"
                name="iname"
                autoFocus={true}
              />
            </Grid>
            <Grid item xs={12} md={12} lg={12}>
              <TextInput
                value={iid}
                onChange={(e) => setIID(e.target.value)}
                label="Institute Code"
                name="idesription"
                disabled={action !== 0}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={12} md={12} lg={12}></Grid>
        </Grid>
      </Grid>

      <Grid container spacing={2}>

      </Grid>
      <Button
        startIcon={loading && <CircularProgress size={20} />}
        fullWidth
        variant="contained"
        color="primary"
        className={classes.submit}
        // onClick={OnSubmit}
        type="submit"
        disabled={loading}
      >
        {action === 1 ? "Update Institute" : "Add Institute"}
      </Button>
    </form>
  );
}
