import React, { useContext, useState } from "react";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import IconButton from "@material-ui/core/IconButton";
import { Box, Divider, Typography } from "@material-ui/core";
import { stateToHTML } from "draft-js-export-html";

import NotificationsActiveIcon from "@material-ui/icons/NotificationsActive";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import AddBoxIcon from "@material-ui/icons/AddBox";

import CustomDialog from "../../ui/CustomDialog/CustomDialog";
import AnnouncementForm from "./AnnouncementForm/AnnouncementForm";
import { convertFromRaw } from "draft-js";
import { AnnouncementContext } from "../../context/AnnouncementContext/AnnouncementContext";
import { AuthContext } from "../../context/AuthContext/AuthContext";

export default function AnnouncementList() {
  const { announcements, deleteAnnouncement } = useContext(AnnouncementContext);
  const { role } = useContext(AuthContext);
  const [showAnnouncementModal, setShowAnnouncementModal] = useState(false);
  const [showAnnouncementEditModal, setShowAnnouncementEditModal] = useState(
    false
  );
  const [announcement, setAnnouncement] = useState(0);

  const onEdit = (index) => {
    setShowAnnouncementEditModal(true);
    setAnnouncement(index);
  };

  const onDelete = (index) => {
    setAnnouncement(index);
    deleteAnnouncement(announcements[index].id);
  };

  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "flex-start",
          alignItems: "center",
          flexWrap: "wrap",
        }}
      >
        <Typography
          style={{
            padding: 8,
          }}
          variant="h6"
          component="h6"
        >
          ANNOUNCEMENTS
        </Typography>
        {role === 0 && (
          <IconButton
            onClick={() => setShowAnnouncementModal(true)}
            edge="end"
            aria-label="edit"
          >
            <AddBoxIcon />
          </IconButton>
        )}
      </div>

      <List style={{ width: "100%" }} dense={false}>
        {announcements &&
          announcements.map((item, index) => (
            <React.Fragment key={index}>
              <ListItem button >
                <ListItemIcon>
                  <NotificationsActiveIcon />
                </ListItemIcon>
                <ListItemText
                  primary={item.title}
                  secondary={
                    new Date(item.createdAt.toDate()).toString()
                  }
                />
                {role === 0 && (
                  <ListItemSecondaryAction>
                    <IconButton
                      onClick={() => onEdit(index)}
                      edge="end"
                      aria-label="edit"
                    >
                      <EditIcon />
                    </IconButton>
                    <IconButton
                      onClick={() => onDelete(index)}
                      edge="end"
                      aria-label="delete"
                    >
                      <DeleteIcon />
                    </IconButton>
                  </ListItemSecondaryAction>
                )}
              </ListItem>
              <Box

                style={{ margin: 20 }}
                dangerouslySetInnerHTML={{
                  __html: stateToHTML(convertFromRaw(item.description)),
                }}
                component="div"
              ></Box>
              <Divider />
            </React.Fragment>
          ))}
      </List>
      {announcements && announcements[announcement] && (
        <CustomDialog
          maxWidth={"md"}
          title="Edit Announcement"
          open={showAnnouncementEditModal}
          setOpen={setShowAnnouncementEditModal}
        >
          <AnnouncementForm
            id={announcements[announcement].id}
            title={announcements[announcement].title}
            description={announcements[announcement].description}
            setOpen={setShowAnnouncementEditModal}
          />
        </CustomDialog>
      )}

      <CustomDialog
        maxWidth={"md"}
        title="Add Announcement"
        open={showAnnouncementModal}
        setOpen={setShowAnnouncementModal}
      >
        <AnnouncementForm action={0} setOpen={setShowAnnouncementModal} />
      </CustomDialog>
    </>
  );
}
