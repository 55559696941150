import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import "firebase/storage";
import "firebase/functions";

// const firebaseOptions = {
//   apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
//   authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
//   projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
//   storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
//   messagingSenderId: process.env.REACT_APP_FIREBASE_SENDER_ID,
//   appId: process.env.REACT_APP_FIREBASE_APP_ID,
//   measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
// };

const firebaseOptions = {
  apiKey: "AIzaSyAePw6VgcS72Gpj3L51W5LA-Xa-kvQ3qMM",
  authDomain: "shiksha-v2.firebaseapp.com",
  projectId: "shiksha-v2",
  storageBucket: "shiksha-v2.appspot.com",
  messagingSenderId: "429157287314",
  appId: "1:429157287314:web:39b6e23459c15a9d77ad4b",
  measurementId: "G-4QS1MB985R",
};

const app = firebase.initializeApp(firebaseOptions);
const storage = app.storage().ref();

// if (window.location.hostname === "localhost") {
//   console.log(
//     "testing locally -- hitting local functions and firestore emulators"
//   );
//   app.auth().useEmulator("http://localhost:9099/");
//   app.firestore().useEmulator("localhost", 8081);
//   app.functions().useEmulator("localhost", 5001);
//   app.storage().useEmulator("localhost", 9199);
// }

export { firebase, storage };
export default app;
