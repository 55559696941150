import React from "react";
import CssBaseline from "@material-ui/core/CssBaseline";
import Box from "@material-ui/core/Box";
import Avatar from "@material-ui/core/Avatar";
import Footer from "../App/Footer/Footer";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import AccountCircleOutlinedIcon from "@material-ui/icons/AccountCircleOutlined";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import StudentRegisterForm from "../../components/StudentRegisterForm";
import { Link } from "react-router-dom";
import ArrowBackOutlinedIcon from "@material-ui/icons/ArrowBackOutlined";

// import Background from "../../assests/loginImage2.jpg";

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100vh",
  },
  image: {
    backgroundImage: "url(https://source.unsplash.com/featured/?studying/300)",
    backgroundRepeat: "no-repeat",
    backgroundColor:
      theme.palette.type === "light"
        ? theme.palette.grey[50]
        : theme.palette.grey[900],
    backgroundSize: "cover",
    backgroundPosition: "center",
  },
  paper: {
    margin: theme.spacing(8, 4),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

export default function SignUp(props) {
  const classes = useStyles();

  return (
    <Grid container component="main" className={classes.root}>
      <CssBaseline />
      <Grid item xs={false} sm={4} md={7} className={classes.image} />
      <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
        <div className={classes.paper}>
          <Avatar className={classes.avatar}>
            <AccountCircleOutlinedIcon />
          </Avatar>
          <Typography
            style={{ marginBottom: "15px" }}
            component="h1"
            variant="h5"
          >
            New Student Registration
          </Typography>

          <StudentRegisterForm action={0} />

          <Grid container>
            <Grid item xs>
              {/* <Link href="#" variant="body2">
                Forgot password?
              </Link> */}

              {/* <Link to={`/`}>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <ArrowBackOutlinedIcon />
                  <Typography>Back to home</Typography>
                </div>
              </Link> */}
            </Grid>
            <Grid item>
              <Link style={{ color: 'inherit', textDecoration: 'underline' }} to={`/`}>
                <Typography style={{ marginTop: 20 }}>Already have an account? Sign In</Typography>
              </Link>
            </Grid>
          </Grid>
          <Box mt={8}>
            <Footer />
          </Box>
        </div>
      </Grid>
    </Grid>
  );
}
