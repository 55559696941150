import React, { useContext, useState } from "react";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
// import Avatar from "@material-ui/core/Avatar";
import AccountCircleOutlinedIcon from "@material-ui/icons/AccountCircleOutlined";
import NightsStayOutlinedIcon from "@material-ui/icons/NightsStayOutlined";
import Brightness4OutlinedIcon from "@material-ui/icons/Brightness4Outlined";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
// import Badge from "@material-ui/core/Badge";
import MenuIcon from "@material-ui/icons/Menu";
// import NotificationsNoneOutlinedIcon from "@material-ui/icons/NotificationsNoneOutlined";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
// import AddIcon from "@material-ui/icons/Add";
// import { useHistory } from "react-router-dom";
import CustomDialog from "../../../ui/CustomDialog/CustomDialog";
import StudentRegisterForm from "../../../components/StudentRegisterForm";
import { AuthContext } from "../../../context/AuthContext/AuthContext";
// import { Switch } from "@material-ui/core";
import { CustomThemeContext } from "../../../context/CustomThemeContext/CustomThemeContext";

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  toolbar: {
    paddingRight: 24, // keep right padding when drawer closed
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  menuButtonHidden: {
    display: "none",
  },
  title: {
    flexGrow: 1,
  },
  small: {
    width: theme.spacing(5),
    height: theme.spacing(5),
  },
  large: {
    width: theme.spacing(7),
    height: theme.spacing(7),
  },
}));

export default function AppHeader(props) {
  const classes = useStyles();
  // const history = useHistory();
  const { activeTheme, toggleTheme } = useContext(CustomThemeContext);
  const { signOut, instituteData, role } = useContext(AuthContext);

  const [anchorElSettingsMenu, setAnchorElSettingsMenu] = React.useState(null);
  const [showProfileModal, setShowProfileModal] = useState(false);

  const handleClickSettingsMenu = (event) => {
    setAnchorElSettingsMenu(event.currentTarget);
  };

  const handleClickProfileMenu = () => {
    setShowProfileModal(true);
    setAnchorElSettingsMenu(null);
  };

  const handleClickLogoutMenuItem = () => {
    signOut();
    setAnchorElSettingsMenu(null);
  };

  return (
    <AppBar
      position="absolute"
      className={clsx(classes.appBar, props.open && classes.appBarShift)}
    >
      <Toolbar className={classes.toolbar}>
        <IconButton
          edge="start"
          color="inherit"
          aria-label="open drawer"
          onClick={props.handleDrawerOpen}
          className={clsx(
            classes.menuButton,
            props.open && classes.menuButtonHidden
          )}
        >
          <MenuIcon />
        </IconButton>
        {/* <IconButton color="inherit">
          <AddIcon />
        </IconButton> */}
        <Typography
          component="h1"
          variant="h6"
          color="inherit"
          noWrap
          className={classes.title}
        >
          {instituteData.institute_name}
        </Typography>
        {/* <IconButton color="inherit">
          <Badge badgeContent={4} color="secondary">
            <NotificationsNoneOutlinedIcon />
          </Badge>
        </IconButton> */}
        <IconButton onClick={toggleTheme} color="inherit">
          {activeTheme === 1 ? (
            <NightsStayOutlinedIcon />
          ) : (
            <Brightness4OutlinedIcon />
          )}
        </IconButton>

        <IconButton onClick={handleClickSettingsMenu} color="inherit">
          <AccountCircleOutlinedIcon />
          {/* <Avatar
            className={classes.small}
            alt="Remy Sharp"
            src="https://picsum.photos/200/300"
          /> */}
        </IconButton>
        <Menu
          id="simple-menu"
          anchorEl={anchorElSettingsMenu}
          keepMounted
          open={Boolean(anchorElSettingsMenu)}
          onClose={() => setAnchorElSettingsMenu(null)}
        >
          {role === 1 && (
            <MenuItem onClick={handleClickProfileMenu}>Profile</MenuItem>
          )}
          {/* <MenuItem onClick={handleClickProfileMenu}>Profile</MenuItem> */}
          <MenuItem onClick={handleClickLogoutMenuItem}>Logout</MenuItem>
        </Menu>
        <CustomDialog
          maxWidth={"md"}
          title="Edit Profile"
          buttonCaption="Add"
          open={showProfileModal}
          setOpen={setShowProfileModal}
        >
          <StudentRegisterForm action={1} setOpen={setShowProfileModal} />
        </CustomDialog>
      </Toolbar>
    </AppBar>
  );
}
