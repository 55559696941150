import React, { useContext } from "react";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import MovieIcon from "@material-ui/icons/Movie";
import PictureAsPdfIcon from "@material-ui/icons/PictureAsPdf";
import TextFieldsIcon from "@material-ui/icons/TextFields";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import VisibilityIcon from "@material-ui/icons/Visibility";
import InsertDriveFileIcon from "@material-ui/icons/InsertDriveFile";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";
// import { useHistory, useRouteMatch } from "react-router-dom";
import { Box, Divider, Typography } from "@material-ui/core";
import { AuthContext } from "../../../../context/AuthContext/AuthContext";
import { CloudDownload } from "@material-ui/icons";
import { AppContext } from "../../../../context/AppContext/AppContext";
// import { CourseContext } from "../../../context/CourseContext/CourseContext";

export default function CustomAccordionItem(props) {
  // let { url } = useRouteMatch();
  // const history = useHistory();

  const {
    section,
    visibilitySectionContent,
    item_no,
    title,
    file,
    description,
    type,
    view_count,
    max_view_count,
    createdAt,
    onEditSectionContent,
    onDeleteSectionContent,
    onHideSectionContent,
    handleGotoCourseModuleContent,
  } = props;

  const { role, userPayments } = useContext(AuthContext);

  const { downloadFile } = useContext(AppContext)

  const CourseContentListItem = (
    <>
      <ListItem button>
        <ListItemIcon>
          {type === 1 ? (
            <MovieIcon />
          ) : type === 2 ? (
            <PictureAsPdfIcon />
          ) : type === 0 ? (
            <TextFieldsIcon />
          ) : (
            <InsertDriveFileIcon />
          )}
        </ListItemIcon>
        <ListItemText
          primary={title}
          secondary={
            <div
              style={{
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "center",
              }}
            >
              {/* <Typography>{description}</Typography> */}
              {/* <Typography> </Typography> */}
              <Typography style={{ fontStyle: "italic" }}>
                {role === 0 && "Total views: " + view_count}
                {type === 1 && " Maximum View Count: " + max_view_count}
              </Typography>
            </div>
          }
        />

        <ListItemSecondaryAction>
          {/* <Link
to={{
  pathname: `${url}/${title}`,
  state: {
    params: { file: file, description: description, type: type },
  },
}}
> */}
          {type === 3 ? <IconButton
            onClick={() => downloadFile(title, type, file)}
            aria-label="share"
          >
            <CloudDownload />
          </IconButton> :
            <IconButton
              onClick={() => handleGotoCourseModuleContent(item_no)}
              aria-label="share"
            >
              <ArrowForwardIcon />
            </IconButton>}
          {/* </Link> */}
          {role === 0 && (
            <>
              <IconButton
                onClick={() => {
                  onHideSectionContent(section, item_no);
                }}
                edge="end"
                aria-label="edit"
              >
                {visibilitySectionContent ? (
                  <VisibilityIcon />
                ) : (
                  <VisibilityOffIcon />
                )}
              </IconButton>
              <IconButton
                onClick={() => onEditSectionContent(section, item_no)}
                edge="end"
                aria-label="edit"
              >
                <EditIcon />
              </IconButton>
              <IconButton
                onClick={() => onDeleteSectionContent(section, item_no, type)}
                edge="end"
                aria-label="delete"
              >
                <DeleteIcon />
              </IconButton>
            </>
          )}
        </ListItemSecondaryAction>
      </ListItem>
      <Divider />
    </>
  );
  // new Date(createdAt.toDate())
  const isValidPayment = () => {
    const paymentDate = new Date(userPayments[0]['year'] + "-" + (userPayments[0]['month']) + "-" + "15");
    const createdDate = new Date(createdAt.toDate())
    const currentDate = new Date()

    const diffTime = Math.abs(currentDate - paymentDate);
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

    // console.log(section, title, item_no, diffDays, diffDays <= 45 ? "valid payment" : "invalid payment")
    if (diffDays <= 45 && currentDate.getDate() <= 15) {
      return true;
    }
    const paymentDateCopied = new Date(JSON.parse(JSON.stringify(paymentDate)));
    paymentDateCopied.setMonth(paymentDateCopied.getMonth() + 1)
    paymentDateCopied.setDate(1)
    // console.log(section, title, item_no, diffDays, paymentDateCopied >= createdDate ? "partially valid payment" : "invalid payment")
    if (paymentDateCopied >= createdDate) {
      return true;
    }
    return false;
  }

  // console.log(isValidPayment)
  return (
    <>
      {role === 1 && userPayments[0] && isValidPayment() && visibilitySectionContent
        ? CourseContentListItem
        : role === 0
          ? CourseContentListItem
          : null}
    </>
  );
}
