import React, { useContext, useEffect, useState } from "react";
// import { useHistory, useLocation } from "react-router-dom";
import app, { firebase, storage } from "../../firebase/firebaseConfig";
import { AuthContext } from "../AuthContext/AuthContext";
// import axios from "axios";
import { useSnackbar } from "notistack";

export const InstituteContext = React.createContext({});

export const InstituteProvider = ({ children }) => {
  const [institutes, setInstitutes] = useState(null);
  const [selectedInstitute, setSelectedInstitute] = useState(null);
  const [initializing, setInitializing] = useState(true);
  const [loading, setLoading] = useState(false);

  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    const getInstitutes = () => {
      setLoading(true);
      const unsubscribe = app
        .firestore()
        .collection("institutes")
        .onSnapshot(
          (querySnapshot) => {
            const arr = [];
            const tableData = [];
            querySnapshot.forEach((doc) => {
              let obj = { id: doc.id, ...doc.data() };
              arr.push({ id: doc.id, ...doc.data() });
              tableData.push(obj);
            });
            console.log(arr)
            setInstitutes(arr);
            setInitializing(false);
            setLoading(false);
          },
          (e) => {
            console.log(e.message);
            enqueueSnackbar(e.message, { variant: "error" });
          }
        );
      return () => unsubscribe();
    };
    getInstitutes();
  }, []);

  const uploadFileAsync = async (instituteId, imageFile, path) => {
    try {
      setLoading(true);
      const uploadTask = await storage
        .child(`${instituteId}/${path}/${imageFile.name}`)
        .put(imageFile);
      // console.log(uploadTask);
      const downloadURL = await uploadTask.ref.getDownloadURL();
      enqueueSnackbar("File uploaded!", { variant: "success" });
      setLoading(false);
      return downloadURL;
    } catch (e) {
      console.error("Error adding document: ", e);
      setLoading(false);
      enqueueSnackbar(e.message, { variant: "error" });
    }
  };

  const addInstitute = async (instituteObj) => {
    try {
      setLoading(true);
      const response = await app
        .firestore()
        .collection("institutes")
        .add({
          ...instituteObj,
          createdAt: firebase.firestore.Timestamp.fromDate(new Date()),
        });
      setLoading(false);
      enqueueSnackbar("Document written!", { variant: "success" });
      return response;
    } catch (e) {
      console.error("Error adding document: ", e);
      setLoading(false);
      enqueueSnackbar(e.message, { variant: "error" });
    }
  };

  const updateInstitute = async (institueId, instituteObj) => {
    try {
      setLoading(true);
      const response = await app
        .firestore()
        .collection("institutes")
        .doc(institueId)
        .update(instituteObj);

      enqueueSnackbar("Document successfully written!", {
        variant: "success",
      });
      setLoading(false);
    } catch (e) {
      console.error("Error writing document: ", e);
      enqueueSnackbar(e.message, { variant: "error" });
      setLoading(false);
    }
  };

  const deleteInstitute = (instituteId) => {
    app
      .firestore()
      .collection("institutes")
      .doc(instituteId)
      .delete()
      .then(() => {
        // console.log("Document successfully deleted!");
        enqueueSnackbar("Document successfully deleted!", {
          variant: "success",
        });
      })
      .catch((e) => {
        console.error("Error removing document: ", e);
        enqueueSnackbar(e.message, { variant: "error" });
      })

      .catch((e) => {
        enqueueSnackbar(e.message, { variant: "error" });
      });
  };

  return (
    <InstituteContext.Provider
      value={{
        institutes,
        initializing,
        loading,
        selectedInstitute,
        setSelectedInstitute,
        addInstitute,
        updateInstitute,
        deleteInstitute,
        uploadFileAsync
      }}
    >
      {children}
    </InstituteContext.Provider>
  );
};
