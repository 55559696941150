import React from "react";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";

export default function CustomCheckBox(props) {
  const { label, value, onChange, name } = props;

  return (
    <FormControlLabel

      control={
        <Checkbox
          checked={value}
          onChange={onChange}
          name={name}
          color="primary"
        />
      }
      label={label}
    />
  );
}
