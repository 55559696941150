import React, { useEffect, useState } from "react";
import cyan from "@material-ui/core/colors/cyan";
import { createMuiTheme } from "@material-ui/core/styles";

export const CustomThemeContext = React.createContext({});

export const CustomThemeProvider = ({ children }) => {
  const customLightThemeObject = {
    palette: {
      type: "light",
    },
  };
  const customDarkThemeObject = {
    palette: {
      type: "dark",
      primary: {
        main: cyan[500],
      },
    },
  };

  const [activeTheme, setActiveTheme] = useState(0);

  useEffect(() => {
    const localActiveTheme = parseInt(localStorage.getItem("active_theme"));
    if (localActiveTheme) {
      // console.log(localActiveTheme);
      setCustomTheme(
        createMuiTheme(
          localActiveTheme === 1
            ? customDarkThemeObject
            : customLightThemeObject
        )
      );
      setActiveTheme(localActiveTheme);
    }
  }, []);

  const toggleTheme = () => {
    if (activeTheme === 0) {
      setCustomTheme(createMuiTheme(customDarkThemeObject));
      setActiveTheme(1);
      localStorage.setItem("active_theme", 1);
    } else {
      setCustomTheme(createMuiTheme(customLightThemeObject));
      setActiveTheme(0);
      localStorage.setItem("active_theme", 0);
    }
  };

  const [customTheme, setCustomTheme] = useState(
    createMuiTheme(customLightThemeObject)
  );

  return (
    <CustomThemeContext.Provider
      value={{
        customTheme,
        activeTheme,
        toggleTheme,
      }}
    >
      {children}
    </CustomThemeContext.Provider>
  );
};
