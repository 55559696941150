import React, { useContext } from "react";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import SelectMenu from "../../../ui/SelectMenu/SelectMenu";
import { CourseContext } from "../../../context/CourseContext/CourseContext";

const useStyles = makeStyles((theme) => ({
    form: {
        width: "100%", // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
}));

export default function ContentSharing(props) {

    const classes = useStyles();
    const {
        courses,
        selectedTempCourse,
        tempCourseModules,
        selectedTempCourseModule,
        tempCourseModuleContents,
        setSelectedTempCourse,
        setSelectedTempCourseModule,
        selectedTempCourseModuleContent,
        setSelectedTempCourseModuleContent
    } = useContext(CourseContext);

    return (
        <form className={classes.form} noValidate>

            <Grid container spacing={2}>
                <Grid item xs={4} md={4} lg={4}>
                    <SelectMenu
                        variant="outlined"
                        size="large"
                        onChange={(e) => setSelectedTempCourse(e.target.value)}
                        data={courses ? courses.map((course, key) => ({
                            value: course.id,
                            label: course.title,
                        })) : []}
                        label="Course"
                        fullWidth={true}
                        value={selectedTempCourse}
                    />
                </Grid>
                <Grid item xs={4} md={4} lg={4}>
                    <SelectMenu
                        variant="outlined"
                        size="large"
                        onChange={(e) => setSelectedTempCourseModule(e.target.value)}
                        data={tempCourseModules ? tempCourseModules.map((courseModule, key) => ({
                            value: courseModule.id,
                            label: courseModule.title,
                        })) : []}
                        label="Course Module"
                        fullWidth={true}
                        value={selectedTempCourseModule}
                    />
                </Grid>
                <Grid item xs={4} md={4} lg={4}>
                    <SelectMenu
                        variant="outlined"
                        size="large"
                        onChange={(e) => setSelectedTempCourseModuleContent(e.target.value)}
                        data={tempCourseModuleContents ? tempCourseModuleContents.map((courseModuleContent, key) => ({
                            value: courseModuleContent.id,
                            label: courseModuleContent.title,
                        })) : []}
                        label="Course Module Content"
                        fullWidth={true}
                        value={selectedTempCourseModuleContent}
                    />
                </Grid>
            </Grid>
        </form>
    );
}
