import React, { useContext } from "react";
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch,
} from "react-router-dom";
import { App, Signin, SignUp, NotFound } from "./containers";
import { AuthContext, AuthProvider } from "./context/AuthContext/AuthContext";
import CompanyLogo from "./assests/companyLogo.jpg";
import { CustomThemeContext } from "./context/CustomThemeContext/CustomThemeContext";
import { MuiThemeProvider } from "@material-ui/core/styles";
import LinearProgress from "@material-ui/core/LinearProgress";
import { Helmet } from "react-helmet";
import { browserDetect, toTitleCase } from "./utils/utils";
import Typography from '@material-ui/core/Typography';

export default function Routes() {
  const { customTheme } = useContext(CustomThemeContext);

  if (!browserDetect()) {
    return (
      <Typography >
        Unsupported browser
      </Typography>
    )
  }

  return (
    <MuiThemeProvider theme={customTheme}>
      <Router>
        <AuthProvider>
          <Switch>
            <Route exact path="/">
              <Signin />
            </Route>
            <Route path="/signup">
              <SignUp />
            </Route>

            <PrivateRoute path="/main">
              <App />
            </PrivateRoute>
            <Route path="*">
              <NotFound />
            </Route>
          </Switch>
        </AuthProvider>
      </Router>
    </MuiThemeProvider>
  );
}

const PrivateRoute = ({ children, ...rest }) => {
  const { user, initializing, role, instituteData } = useContext(AuthContext);

  if (initializing) {
    return (
      <div
        style={{
          width: "100%",
        }}
      >
        <LinearProgress />
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
            height: "99vh",
          }}
        >
          <img alt="companyLogo" id="logo" src={CompanyLogo} />
        </div>
      </div>
    );
  }
  // userPayments.length > 0
  // console.log(instituteData);
  return (
    <>
      <Helmet>
        <title>
          {instituteData &&
            instituteData.name &&
            toTitleCase(instituteData.name)}
        </title>
      </Helmet>
      <Route
        {...rest}
        render={({ location }) =>
          user ? (
            children
          ) : (
            <Redirect
              to={{
                pathname: "/",
                state: { from: location },
              }}
            />
          )
        }
      />
    </>
  );
};
