import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
// import Paper from "@material-ui/core/Paper";
import StudentListTable from "../../../../components/StudentListTable";
import { StudentProvider } from "../../../../context/StudentContext/StudentContext";
import PageTitle from "../../../../ui/PageTitle/PageTitle";
import AdministratorTable from "../../../../components/AdministratorTable";
const useStyles = makeStyles((theme) => ({
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: "100vh",
    overflow: "auto",
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(1),
  },
  fixedHeight: {
    height: "100%",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
  },
}));

export default function Administrators() {
  const classes = useStyles();

  // const fixedHeightPaper = clsx(classes.paper, classes.fixedHeight);

  return (
    <main className={classes.content}>
      <div className={classes.appBarSpacer} />
      <PageTitle title={"Administrator Management"} />
      <Container maxWidth="lg" className={classes.container}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={12} lg={12}>
            {/* <Paper className={fixedHeightPaper}> */}
            <AdministratorTable />
            {/* </Paper> */}
          </Grid>
        </Grid>
      </Container>
    </main>
  );
}
