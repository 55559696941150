import React from "react";
import { TextField } from "@material-ui/core";

export default function TextInput(props) {
  const {
    multiline,
    label,
    rows,
    type,
    error,
    disabled,
    helperText,
    name,
    rowsMax,
    value,
    onChange,
    margin,
    autoFocus,
    size,
    InputProps,
    onInput,
    onBlur,
    onFocus,
    min,
  } = props;

  return (
    <TextField
      style={{ marginBottom: 5 }}
      name={name}
      margin={margin}
      label={label}
      multiline={multiline}
      rows={rows}
      rowsMax={rowsMax}
      value={value}
      type={type}
      autoFocus={autoFocus}
      // placeholder={label}
      autoComplete
      onChange={onChange}
      variant="outlined"
      error={error}
      disabled={disabled}
      helperText={error ? helperText : null}
      size={size}
      onFocus={onFocus}
      fullWidth={true}
      InputProps={InputProps}
      onBlur={onBlur}
      onInput={onInput}
      min={min}
    />
  );
}
