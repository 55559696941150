import React, { useContext, useEffect, useState } from "react";
// import { useHistory, useLocation } from "react-router-dom";
import app, { firebase } from "../../firebase/firebaseConfig";
import { AuthContext } from "../AuthContext/AuthContext";
import { CourseContext } from "../CourseContext/CourseContext";
import { useSnackbar } from "notistack";
import { Days } from "../../constants/Days";
import { Genders } from "../../constants/Genders";

export const StudentContext = React.createContext({});

export const StudentProvider = ({ children }) => {
  const { institute } = useContext(AuthContext);
  const { courses } = useContext(CourseContext);
  const [students, setStudents] = useState(null);
  const [studentsTableData, setStudentsTableData] = useState(null);
  const [selectedCourses, setSelectedCourses] = useState([1]);
  const [studentDataLoading, setStudentDataLoading] = useState(false);
  const [initializing, setInitializing] = useState(true);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);
  const { enqueueSnackbar } = useSnackbar();



  const addStudent = (studentObj) => {
    app
      .firestore()
      .collection("announcements")
      .add({
        institute: institute,
        ...studentObj,
        createdAt: firebase.firestore.Timestamp.fromDate(new Date()),
      })
      .then((docRef) => {
        setSuccess("Document written with ID: ", docRef.id);
        console.log("Document written with ID: ", docRef.id);
        enqueueSnackbar("Document written", { variant: "success" });
      })
      .catch((e) => {
        setError(e.message);
        console.error("Error adding document: ", e);
        enqueueSnackbar(e.message, { variant: "error" });
      });
  };

  const updateStudent = (studentId, studentObj) => {
    app
      .firestore()
      .collection("users")
      .doc(studentId)
      .update(studentObj)
      .then(() => {
        setSuccess("Document successfully written!");
        console.log("Document successfully written!");
        enqueueSnackbar("Document successfully updated!", {
          variant: "success",
        });
      })
      .catch((e) => {
        setError("Error writing document: ", e);
        console.error("Error writing document: ", e);
        enqueueSnackbar(e.message, { variant: "error" });
      });
  };

  const deleteStudent = (studentId) => {
    // delete by admin
    app
      .functions()
      .httpsCallable("api/deleteUser")({ uid: studentId })
      .then((result) => {
        console.log("Successfully deleted user", studentId);
        enqueueSnackbar("Successfully deleted user", { variant: "success" });
      })
      .catch((e) => {
        setError(e.message);
        console.log("Error deleting user:", e);
        enqueueSnackbar(e.message, { variant: "error" });
      });
  };

  useEffect(() => {
    const getStudentsByCourse = () => {
      setStudentDataLoading(true);
      app
        .firestore()
        .collection("users")
        .where("institute", "==", institute)
        .where("role", "==", 1)
        .where(
          "courses",
          "array-contains-any",
          selectedCourses && selectedCourses.length > 0 ? selectedCourses : [1]
        )
        .get()
        .then((querySnapshot) => {
          const arr = [];
          const tableData = []
          querySnapshot.forEach((doc) => {
            let obj = { id: doc.id, ...doc.data() }
            obj['gender'] = Genders[obj['gender']]
            arr.push({ id: doc.id, ...doc.data() });
            tableData.push(obj)
          });
          setStudents(arr);
          setStudentsTableData(tableData)
          setInitializing(false);
          setStudentDataLoading(false);
          // enqueueSnackbar("Student data loaded successfully!", {
          //   variant: "success",
          // });
        })
        .catch((e) => {
          enqueueSnackbar(e.message, { variant: "error" });
        });
    };
    getStudentsByCourse();
    if (courses && courses[0] && selectedCourses[0] === 1) {
      setSelectedCourses([courses[0].id]);
    }
  }, [selectedCourses, courses, institute, enqueueSnackbar]);

  return (
    <StudentContext.Provider
      value={{
        students,
        studentsTableData,
        error,
        initializing,
        success,
        studentDataLoading,
        selectedCourses,
        setSelectedCourses,
        addStudent,
        updateStudent,
        deleteStudent,
      }}
    >
      {children}
    </StudentContext.Provider>
  );
};
