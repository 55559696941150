import React, { useState, useContext } from "react";
// import { makeStyles } from "@material-ui/core/styles";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
// import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import List from "@material-ui/core/List";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import AccordionActions from "@material-ui/core/AccordionActions";
import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import MovieIcon from "@material-ui/icons/Movie";
import PictureAsPdfIcon from "@material-ui/icons/PictureAsPdf";
import TextFieldsIcon from "@material-ui/icons/TextFields";
import VisibilityIcon from "@material-ui/icons/Visibility";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";
import KeyboardArrowRightIcon from "@material-ui/icons/KeyboardArrowRight";
import { Divider, Tooltip } from "@material-ui/core";
import CustomAccordionItem from "./CustomAccordionItem/CustomAccordionItem";
import { AuthContext } from "../../../context/AuthContext/AuthContext";
import { Archive, InsertDriveFile, Share } from "@material-ui/icons";

// const useStyles = makeStyles({
//   root: {
//     width: "100%",
//     flexWrap: "wrap",
//   },
// });

export default function CustomAccordionGroup(props) {
  // let { url } = useRouteMatch();

  const {
    section,
    loading,
    expanded,
    onChangeExpanded,
    visibilitySection,
    data,
    title,
    onEditSection,
    onDeleteSection,
    onHideSection,
    onEditSectionContent,
    onDeleteSectionContent,
    onHideSectionContent,
    onAddFile,
    handleGotoCourseModuleContent,
  } = props;

  const { role } = useContext(AuthContext);
  const [visibility, setVisibility] = useState(visibilitySection);
  // console.log(role === 1 && visibilitySection);
  const CourseAccordionGroup = (
    <Accordion
      expanded={expanded === section}
      onChange={onChangeExpanded(section)}
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-label="Expand"
        aria-controls="additional-actions1-content"
        id="additional-actions1-header"
      >
        <FormControlLabel
          aria-label="Acknowledge"
          onClick={(event) => event.stopPropagation()}
          onFocus={(event) => event.stopPropagation()}
          control={<KeyboardArrowRightIcon />}
          label={title}
        />
        {role === 0 && (
          <AccordionActions>
            <IconButton
              onClick={(event) => {
                event.stopPropagation();
                onEditSection(section);
              }}
              onFocus={(event) => event.stopPropagation()}
              edge="end"
              aria-label="add"
            >
              <EditIcon />
            </IconButton>
            <IconButton
              edge="end"
              aria-label="edit"
              onClick={(event) => {
                event.stopPropagation();
                onHideSection(section);
                setVisibility(!visibility);
              }}
              onFocus={(event) => event.stopPropagation()}
            >
              {visibility ? <VisibilityIcon /> : <VisibilityOffIcon />}
            </IconButton>
            <IconButton
              edge="end"
              aria-label="delete"
              onClick={(event) => {
                event.stopPropagation();
                onDeleteSection(section);
              }}
              onFocus={(event) => event.stopPropagation()}
            >
              <DeleteIcon />
            </IconButton>
          </AccordionActions>
        )}
      </AccordionSummary>

      <AccordionDetails>
        <List style={{ width: "100%" }} dense={false}>
          {!loading &&
            data.map((item, item_no) => (
              <>
                <CustomAccordionItem
                  key={item_no}
                  file={item.file}
                  type={item.type}
                  title={item.title}
                  item_no={item_no}
                  section={section}
                  max_view_count={item.max_view_count}
                  visibilitySectionContent={item.visibility}
                  description={item.description}
                  view_count={item.view_count}
                  createdAt={item.createdAt}
                  onHideSectionContent={onHideSectionContent}
                  onEditSectionContent={onEditSectionContent}
                  onDeleteSectionContent={onDeleteSectionContent}
                  handleGotoCourseModuleContent={handleGotoCourseModuleContent}
                />
              </>
            ))}
        </List>
      </AccordionDetails>
      <Divider />
      {role === 0 && (
        <AccordionActions style={{ padding: "20px" }}>
          <Tooltip title="Share Content">
            <IconButton
              onClick={() => onAddFile(4, section)}
              edge="end"
              aria-label="share"
            >
              <Share />
            </IconButton>
          </Tooltip>
          <Tooltip title="Zip File">
            <IconButton
              onClick={() => onAddFile(3, section)}
              edge="end"
              aria-label="zip"
            >
              <InsertDriveFile />
            </IconButton>
          </Tooltip>
          <IconButton
            onClick={() => onAddFile(1, section)}
            edge="end"
            aria-label="video"
          >
            <MovieIcon />
          </IconButton>
          <IconButton
            onClick={() => onAddFile(2, section)}
            edge="end"
            aria-label="doc"
          >
            <PictureAsPdfIcon />
          </IconButton>
          <IconButton
            onClick={() => onAddFile(0, section)}
            edge="end"
            aria-label="text"
          >
            <TextFieldsIcon />
          </IconButton>
        </AccordionActions>
      )}
    </Accordion>
  );
  return (
    <>
      {role === 1 && visibilitySection
        ? CourseAccordionGroup
        : role === 0
          ? CourseAccordionGroup
          : null}
    </>
  );
}
