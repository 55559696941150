import React, { useContext, useEffect, useState } from "react";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import TextInput from "../../../../ui/TextInput/TextInput";
import TextEditor from "../../../../ui/TextEditor/TextEditor";
import DropZone from "../../../../ui/DropZone/DropZone";
import { convertToRaw, EditorState, convertFromRaw } from "draft-js";
import SelectMenu from "../../../../ui/SelectMenu/SelectMenu";
import { Resolutions, maxViewCounts, FPSs } from "../../../../constants/Video";
import CustomCheckBox from "../../../../ui/CustomCheckBox/CustomCheckBox";
import { CourseContext } from "../../../../context/CourseContext/CourseContext";
import { createFFmpeg, fetchFile } from '@ffmpeg/ffmpeg';
import CustomDatePicker from "../../../../ui/CustomDatePicker/CustomDatePicker";
import ContentSharing from "../../ContentSharing/ContentSharing";

const useStyles = makeStyles((theme) => ({
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

export default function SectionContentForm(props) {

  const classes = useStyles();
  const {
    courses,
    addCourseModuleContent,
    updateCourseModuleContent,
    uploadingProgress,
    selectedTempCourse,
    selectedTempCourseModule,
    tempCourseModuleContents,
    selectedTempCourseModuleContent
  } = useContext(CourseContext);
  const {
    title,
    description,
    type,
    action,
    id,
    courseModule,
    setOpen,
    data,
    max_view_count,
    downloadable,
    createdAt
  } = props;

  const [ttl, setTitle] = useState(title ? title : "");
  const [desc, setDesc] = useState(description ? description : "");
  const [maxViewCount, setMaxViewCount] = useState(
    max_view_count ? max_view_count : 2
  );
  const [resolution, setResolution] = useState(Resolutions[1]);
  const [fps, setFPS] = useState(FPSs[0]);
  const [download, setDownload] = useState(downloadable ? downloadable : false);

  const [createdDate, setCreatedAt] = useState(createdAt ? createdAt.toDate() : new Date());
  const [content, setContent] = useState(
    type === 0 ? data
      ? EditorState.createWithContent(convertFromRaw(data))
      : EditorState.createEmpty() : null
  );
  const [files, setFiles] = useState([]);

  const [fileProgress, setFileProgress] = useState(0)
  const [fileProgressMessage, setFileProgressMessage] = useState('')
  const [filePreview, setFilePreview] = useState(null)

  const ffmpeg = createFFmpeg({
    log: true,
    progress: ({ ratio }) => {
      setFileProgress((ratio * 100.0).toFixed(2))
    },
  });

  useEffect(() => {
    setFileProgress(uploadingProgress)
  }, [uploadingProgress])

  const onSubmit = async () => {
    if (ttl === "" || desc === "") {
      return
    }

    setFileProgressMessage('Uploading')

    if (type === 0) {
      const obj = {
        courseModule: courseModule,
        title: ttl,
        description: desc,
        type: type,
        visibility: true,
        createdAt: createdDate,
        // max_view_count: maxViewCount,
        view_count: 0,
        file: convertToRaw(content.getCurrentContent()),
      };
      addCourseModuleContent(type, obj, setOpen);
    } else if (type === 1 && files.length > 0) {
      const obj = {
        courseModule: courseModule,
        title: ttl,
        description: desc,
        type: type,
        visibility: true,
        max_view_count: maxViewCount,
        view_count: 0,
        resolution: resolution,
        fps: fps,
        downloadable: download,
        file: files,
        createdAt: createdDate
      };
      addCourseModuleContent(type, obj, setOpen);
    } else if ((type === 2 || type === 3) && files.length > 0) {
      const obj = {
        courseModule: courseModule,
        title: ttl,
        description: desc,
        type: type,
        visibility: true,
        view_count: 0,
        downloadable: download,
        file: files,
        createdAt: createdDate
      };
      console.log(obj)
      addCourseModuleContent(type, obj, setOpen);
    } else if (type === 4 && selectedTempCourseModuleContent) {
      let content = tempCourseModuleContents.find(tempCourseModuleContent => tempCourseModuleContent.id === selectedTempCourseModuleContent)
      let content_url = content && content.file
      let content_type = content && content.type
      let content_max_view_count = content_type && content_type === 1 && content && content.max_view_count
      const obj = {
        courseModule: courseModule,
        parentCourse: selectedTempCourse,
        parentCourseModule: selectedTempCourseModule,
        title: ttl,
        description: desc,
        type: content_type,
        contentType: type,
        visibility: true,
        view_count: 0,
        max_view_count: content_max_view_count,
        downloadable: download,
        file: content_url,
        createdAt: createdDate
      };
      console.log(obj)
      addCourseModuleContent(type, obj, setOpen);
    }
  };

  const onUpdate = () => {
    console.log('update')
    if (ttl !== "" && desc !== "") {
      console.log('update2')
      if (type === 0) {
        const obj = {
          title: ttl,
          description: desc,
          file: convertToRaw(content.getCurrentContent()),
          createdAt: createdDate
        };
        updateCourseModuleContent(
          id,
          obj,
          setOpen
        );
      } else if (type === 1) {
        const obj = {
          title: ttl,
          description: desc,
          max_view_count: maxViewCount,
          resolution: resolution,
          fps: fps,
          downloadable: download,
          createdAt: createdDate
        };
        console.log(obj)
        updateCourseModuleContent(
          id,
          obj,
          setOpen
        );
      } else if (type === 2) {
        const obj = {
          title: ttl,
          description: desc,
          downloadable: download,
          createdAt: createdDate
        };
        console.log(obj)
        updateCourseModuleContent(
          id,
          obj,
          setOpen
        );
      } else if (type === 4 && selectedTempCourseModuleContent) {
        let content = tempCourseModuleContents.find(tempCourseModuleContent => tempCourseModuleContent.id === selectedTempCourseModuleContent)
        let content_url = content && content.file
        let content_type = content && content.type
        const obj = {
          courseModule: courseModule,
          parentCourse: selectedTempCourse,
          parentCourseModule: selectedTempCourseModule,
          title: ttl,
          description: desc,
          type: content_type,
          visibility: true,
          view_count: 0,
          downloadable: download,
          file: content_url,
          createdAt: createdDate
        };
        console.log(obj)
        updateCourseModuleContent(
          id,
          obj,
          setOpen
        );
      }

    }
  };

  const doTranscode = async () => {
    try {
      const { name } = files[0]
      console.log(files[0])
      setFileProgressMessage('Initializing');
      await ffmpeg.load();
      setFileProgressMessage('Start transcoding');
      ffmpeg.FS('writeFile', name, await fetchFile(files[0]));
      await ffmpeg.run('-i', name, '-r', fps.toString(), '-s', resolution, 'test.mp4');

      setFileProgressMessage('Complete transcoding');
      const data = ffmpeg.FS('readFile', 'test.mp4');
      const blob = new Blob([data.buffer], { type: 'video/mp4' })
      console.log(blob, data.byteLength, data.length);
      setFilePreview(URL.createObjectURL(blob))
      const fileObject = new File([blob], name, { type: 'video/mp4' })
      console.log(fileObject)
      setFiles([fileObject])
      setFileProgressMessage('Converted')
    } catch (err) {
      console.log(err)
    }

  };

  return (
    <form className={classes.form} noValidate>
      <Grid container spacing={2}>
        <Grid item xs={12} md={12} lg={12}>
          <TextInput
            label="Title"
            name="title"
            autoFocus={true}
            size="large"
            value={ttl}
            onChange={(e) => setTitle(e.target.value)}
            helperText="The title feild cannot be empty"
          />
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={12} md={12} lg={12}>
          <TextInput
            label="Decription"
            name="desc"
            size="large"
            value={desc}
            rows={4}
            multiline={true}
            onChange={(e) => setDesc(e.target.value)}
            helperText="The description feild cannot be empty"
          />
        </Grid>
        {type === 1 && (
          <>
            <Grid item xs={3} md={3} lg={3}>
              <SelectMenu
                variant="outlined"
                size="large"
                onChange={(e) => setMaxViewCount(e.target.value)}
                data={maxViewCounts.map((view, i) => ({
                  value: view,
                  label: view,
                }))}
                label="Maximum View Attempts"
                fullWidth={true}
                value={maxViewCount}
              />
            </Grid>
            {action == 0 && <><Grid item xs={3} md={3} lg={3}>
              <SelectMenu
                variant="outlined"
                size="large"
                onChange={(e) => setResolution(e.target.value)}
                data={Resolutions.map((resol, i) => ({
                  value: resol,
                  label: resol,
                }))}
                label="Resolution"
                fullWidth={true}
                value={resolution}
              />
            </Grid><Grid item xs={3} md={3} lg={3}>
                <SelectMenu
                  variant="outlined"
                  size="large"
                  onChange={(e) => setFPS(e.target.value)}
                  data={FPSs.map((fpS, i) => ({
                    value: fpS,
                    label: fpS,
                  }))}
                  label="FPS"
                  fullWidth={true}
                  value={fps}
                />
              </Grid></>}



          </>
        )}
        <Grid item xs={3} md={3} lg={3}>
          <CustomDatePicker
            value={createdDate}
            onChange={(e) => setCreatedAt(e)}
            size="large"
            label="Published Date"
          />
        </Grid>
        {type !== 0 && type !== 3 && <Grid item xs={3} md={3} lg={3}>
          <CustomCheckBox
            onChange={(e) => setDownload(e.target.checked)}
            value={download}
            label="Downloadable"
            name="downloadable"
          />
        </Grid>}
      </Grid>

      <Grid container spacing={2}>
        <Grid item xs={12} md={12} lg={12}>
          {action === 0 && (type === 1 || type === 2 || type === 3) ? (
            <DropZone
              maxFiles={1}
              type={type}
              title={"Drag 'n' drop some files here, or click to select files"}
              dragActiveTitle={"Drop the files here ..."}
              subtitle={
                "(1 file is the maximum number of files you can drop here)"
              }
              files={files}
              setFiles={setFiles}
              progress={fileProgress}
              progressMessage={fileProgressMessage}
              maxSize={type === 1 ? 1000000000 : type === 2 ? 10000000 : type === 3 ? 50000000 : 0}
              preview={filePreview}
              convert={type === 1 && doTranscode}
            />
          ) : type === 0 ? (
            <TextEditor
              value={content}
              onChange={(editor_data) => setContent(editor_data)}
              placeholder="  Enter description"
            />
          ) : type === 4 && <ContentSharing data={{ courses }} />}
        </Grid>
      </Grid>
      {/* <Divider variant="fullWidth" /> */}

      {action === 0 ? (
        <Button
          fullWidth
          variant="contained"
          color="primary"
          className={classes.submit}
          onClick={onSubmit}
        >
          Add
        </Button>
      ) : (
        <Button
          fullWidth
          variant="contained"
          color="primary"
          className={classes.submit}
          onClick={onUpdate}
        >
          Update
        </Button>
      )}
    </form>
  );
}
