import React, { useContext, useEffect, useState } from "react";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import DataTable from "../../ui/DataTable/DataTable";
import SelectMenu from "../../ui/SelectMenu/SelectMenu";
import { Button } from "@material-ui/core";
// import Hidden from "@material-ui/core/Hidden";
import { CourseContext } from "../../context/CourseContext/CourseContext";
import SearchBar from "../../ui/SearchBar/SearchBar";
import { StudentContext } from "../../context/StudentContext/StudentContext";
import { Years } from "../../constants/Years";
import { Months } from "../../constants/Months";
import { PaymentContext } from "../../context/PaymentContext/PaymentContext";

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(1),
  },
  fixedHeight: {
    height: "100%",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
  },
}));

export default function PaymentsTable() {
  const classes = useStyles();
  const {
    payments,
    addPayment,
    selectedMonth,
    selectedYear,
    setSelectedMonth,
    setSelectedYear,
    deletePayment,
    paymentDataLoading,
    selectedStudent,
    setSelectedStudent,
    selectedStudentsByCourse,
    setSelectedCourse,
    selectedCourse,
  } = useContext(PaymentContext);

  const { courses } = useContext(CourseContext);
  const [paymentsData, setPaymentsData] = useState(payments);
  const fixedHeightPaper = clsx(classes.paper, classes.fixedHeight);

  useEffect(() => {
    setPaymentsData(payments);
  }, [payments]);

  return (
    <>
      <Grid container spacing={3}>
        <Grid item xs={12} md={3} lg={3}>
          <Paper className={fixedHeightPaper}>
            <SelectMenu
              data={
                courses
                  ? courses.map((item) => ({
                    value: item.id,
                    label: item.title,
                  }))
                  : []
              }
              label="Select Course"
              width={"100%"}
              value={selectedCourse ? selectedCourse : ""}
              fullWidth={true}
              // SelectProps={{ multiple: true }}
              onChange={(e) => {
                setSelectedCourse(e.target.value);
              }}
            />
          </Paper>
        </Grid>
        {/* <Hidden xsDown>
          <Grid item xs={12} md={4} lg={4}></Grid>
        </Hidden> */}

        <Grid item xs={12} md={2} lg={2}>
          <Paper className={fixedHeightPaper}>
            <SelectMenu
              data={Years.map((val) => ({ value: val, label: val.toString() }))}
              label="Select Year"
              width={"100%"}
              value={selectedYear}
              fullWidth={true}
              onChange={(e) => {
                setSelectedYear(e.target.value);
              }}
            />
          </Paper>
        </Grid>
        <Grid item xs={12} md={2} lg={2}>
          <Paper className={fixedHeightPaper}>
            <SelectMenu
              data={Months.map((val, index) => ({
                value: index + 1,
                label: val,
              }))}
              label="Select Month"
              width={"100%"}
              value={selectedMonth}
              fullWidth={true}
              onChange={(e) => {
                setSelectedMonth(e.target.value);
              }}
            />
          </Paper>
        </Grid>
        <Grid item xs={12} md={3} lg={3}>
          <Paper className={fixedHeightPaper}>
            <SearchBar
              label="Select Student"
              data={selectedStudentsByCourse ? selectedStudentsByCourse : []}
              getOptionLabel={(option) => `${option.username} (${option.fname} ${option.lname})`}
              onChange={(event, newValue) => setSelectedStudent(newValue)}
            />
          </Paper>
        </Grid>

        <Grid item xs={12} md={2} lg={2}>
          <Paper className={fixedHeightPaper}>
            <Button
              disabled={paymentDataLoading}
              variant="contained"
              color="primary"
              fullWidth
              style={{ height: "100%" }}
              onClick={() =>
                addPayment({
                  course: selectedCourse,
                  year: selectedYear,
                  month: selectedMonth,
                  userObj: selectedStudent,
                })
              }
            >
              Pay
            </Button>
          </Paper>
        </Grid>

        <Grid item xs={12} md={12} lg={12}>
          <DataTable
            grouping={true}
            exportButton={true}
            paging={true}
            title={`${courses &&
              courses
                .filter((val) => selectedCourse && selectedCourse === val.id)
                .map((val) => val.title)
              }(${selectedYear ? selectedYear : ""}/${selectedMonth ? Months[selectedMonth - 1] : ""
              })`}
            columns={[
              { title: "Username", field: "username" },
              { title: "First name", field: "fname" },
              { title: "Last name", field: "lname" },
              { title: "NIC", field: "nic" },
              { title: "Month", field: "month" },
              { title: "Year", field: "year" },
            ]}
            data={
              !paymentDataLoading && paymentsData
                ? JSON.parse(JSON.stringify(paymentsData)).map((item) => {
                  // console.log(item);
                  item.month = Months[item.month - 1];
                  return item;
                })
                : []
            }
            editable={{
              onRowDelete: (oldData) =>
                new Promise((resolve) => {
                  resolve();
                  setPaymentsData((prevState) => {
                    const removedData = [...prevState];
                    removedData.splice(removedData.indexOf(oldData), 1);
                    return removedData;
                  });
                  deletePayment(oldData.id);
                }),
            }}
          />
        </Grid>
      </Grid>
    </>
  );
}
