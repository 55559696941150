import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import { IconButton } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import { CloudDownload } from "@material-ui/icons";
import AddBoxIcon from "@material-ui/icons/AddBox";

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(1),
    display: "flex",
    overflow: "auto",
    flexDirection: "row",
    borderRadius: 0,
    height: "auto",
    justifyContent: "flex-start",
    alignItems: "center",
  },
  title: {
    padding: theme.spacing(1),
    display: "flex",
  },
}));

export default function PageTitle(props) {
  const classes = useStyles();
  const history = useHistory();
  const { title, back, children } = props;

  return (
    <Paper className={classes.paper}>
      {back && (
        <IconButton
          style={{ marginRight: 10 }}
          onClick={() => history.goBack()}
          edge="end"
          aria-label="back"
        >
          <ArrowBackIcon />
        </IconButton>
      )}
      <Typography
        className={classes.title}
        component="h3"
        variant="h5"
        color="textPrimary"
      >
        {title}
      </Typography>
      {children}
    </Paper>
  );
}
