import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import app, { firebase } from "../../firebase/firebaseConfig";
import { useCookies } from "react-cookie";
import { useSnackbar } from "notistack";

export const AuthContext = React.createContext({});

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(false);
  const [initializing, setInitializing] = useState(true);
  const [institute, setInstitute] = useState("");
  const [instituteData, setInstituteData] = useState(null);
  const [role, setRole] = useState(null);
  const [profile, setProfile] = useState(null);
  const [userPayments, setUserPayments] = useState(null);

  const history = useHistory();

  const [cookies, setCookie, removeCookie] = useCookies(["device_id"]);

  const location = useLocation();

  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    app.auth().onAuthStateChanged(async (currentUser) => {
      setInitializing(true);
      if (currentUser) {
        setUser(currentUser);
        let userDetails = await getUser(currentUser);
        setRole(userDetails.role);
        setProfile(userDetails);

        let instituteDetails = await getInstitute(userDetails.institute);
        setInstitute(instituteDetails.id);
        setInstituteData(instituteDetails);

        let userPaymentDetails = await getLatestUserPayment(currentUser);
        setUserPayments(userPaymentDetails);

        if (userDetails.role === 1) {
          if (checkDeviceId(userDetails.id, userDetails.device_id, userDetails.role)) {
            history.push('/main/dashboard');
          } else {
            enqueueSnackbar("Device verification failed", { variant: "error" });
          }
        } else if (userDetails.role === 3) {
          history.push('/main/institute');
        } else {
          history.push('/main/dashboard');
        }
      } else {
        setUser(null);
      }
      setInitializing(false);
    });
  }, []);

  const updateUserAuthByAdmin = (uid, userObj) => {
    app
      .functions()
      .httpsCallable("api/updateUser")({ uid: uid, userObj: userObj })
      .then((result) => {
        console.log("Successfully updated user", result.data.uid);
        enqueueSnackbar("Successfully updated user", { variant: "success" });
      })
      .catch((e) => {
        console.log("Error updating user:", e);
        enqueueSnackbar(e.message, { variant: "error" });
      });
  };

  const createUserByAdmin = async (userObj) => {
    try {
      let response = await app
        .functions()
        .httpsCallable("api/createUser")({ userObj })
      console.log("Successfully created user", response.data);
      enqueueSnackbar("Successfully created user", { variant: "success" });
      return response.data
    } catch (e) {
      console.log("Error creating user:", e);
      enqueueSnackbar(e.message, { variant: "error" });
      return null
    }
  }

  const getInstituteByCode = async (iid) => {
    try {
      const response = await app
        .firestore()
        .collection("instituteCodes")
        .doc(iid)
        .get();
      const data = await response.data();
      return { id: response.id, ...data };
      // const institutes = [];
      // response.forEach((doc) => {
      //   institutes.push({ id: doc.id, ...doc.data() });
      // });
      // if (!institutes[0]) {
      //   enqueueSnackbar("Institute code is invalid", { variant: "error" });
      // }
      // console.log(institutes[0]);
      // return institutes[0];
    } catch (e) {
      console.log(e.message);
      enqueueSnackbar(e.message, { variant: "error" });
      return null;
    }
  };

  const signIn = async (email, password) => {
    try {
      setInitializing(true)
      const response = await app
        .auth()
        .signInWithEmailAndPassword(email, password);
      setInitializing(false)
    } catch (e) {
      setInitializing(false)
      enqueueSnackbar(e.message, { variant: "error" });
      console.log(e.message);
    }
  };

  const updateUser = (userObj, setOpen) => {
    setLoading(true);
    if (userObj.dob) {
      userObj.dob = firebase.firestore.Timestamp.fromDate(userObj.dob);
    }
    app
      .firestore()
      .collection("users")
      .doc(user.uid)
      .update(userObj)
      .then(async (obj) => {
        enqueueSnackbar("Document written successfully!", {
          variant: "success",
        });
        // console.log(obj);
        setOpen(false);
        setLoading(false);
        const userDetails = await getUser(user);
        setProfile(userDetails);
      })
      .catch((e) => {
        enqueueSnackbar(e.message, { variant: "error" });
        setLoading(false);
      });
  };

  const updateUserCredentials = (newPassword, setOpen) => {
    setLoading(true);
    user
      .updatePassword(newPassword)
      .then(function () {
        // Update successful.
        enqueueSnackbar("Update successful!", { variant: "success" });
        signOut();
        setOpen(false);
      })
      .catch((e) => {
        // An error happened.
        enqueueSnackbar(e.message, { variant: "error" });
      });
  };

  const checkDeviceId = async (user_id, device_id, user_role) => {
    if (device_id === "") {
      await updateUserCookie(user_id);
    } else {
      if (device_id === cookies.device_id) {
        return true;
      } else {
        return false
      }
    }
  };

  const signUp = async (userObj) => {
    try {
      setInitializing(true)
      let { username, password } = userObj;
      let response = await app
        .auth()
        .createUserWithEmailAndPassword(username, password);

      userObj['dob'] = firebase.firestore.Timestamp.fromDate(userObj.dob);
      await addUser({ id: response.user.uid, ...userObj });
      let userDetails = await getUser(response.user);

      setRole(userObj.role);
      setProfile(userObj);

      let userPaymentDetails = await getLatestUserPayment(response.user);
      setUserPayments(userPaymentDetails);

      await checkDeviceId(
        response.user.uid,
        userDetails.device_id,
        userDetails.role
      );
      setInitializing(false)
    } catch (e) {
      setInitializing(false)
      enqueueSnackbar(e.message, { variant: "error" });
    }
  };

  const addUser = async (userObj) => {
    delete userObj.password;
    try {
      await app.firestore().collection("users").doc(userObj.id).set(userObj);
    } catch (e) {
      console.log(e.message);
      enqueueSnackbar(e.message, { variant: "error" });
    }
  };

  const getUser = async (new_user) => {
    try {
      const response = await app
        .firestore()
        .collection("users")
        .doc(new_user.uid)
        .get();

      const data = await response.data();
      return data;
    } catch (e) {
      console.log(e.message);
      enqueueSnackbar(e.message, { variant: "error" });
    }
  };

  const getUserByUname = async (uname) => {
    try {
      const response = await app
        .firestore()
        .collection("users")
        .where("uname", "==", uname)
        .get();
      const users = [];
      response.forEach((doc) => {
        users.push({ id: doc.id, ...doc.data() });
      });

      if (!users[0]) {
        enqueueSnackbar("Username is invalid", { variant: "error" });
      }

      return users[0];
    } catch (e) {
      console.log(e.message);
      enqueueSnackbar(e.message, { variant: "error" });
    }
  };

  const getLatestUserPayment = async (new_user) => {
    try {

      const response = await app
        .firestore()
        .collection("payments")
        .where("user", "==", new_user.uid)
        .limitToLast(1)
        .orderBy('createdAt')
        .get();

      const arr = [];
      for (let doc of response.docs) {
        arr.push({ id: doc.id, ...doc.data() });
      }

      return arr;

    } catch (e) {
      console.log(e.message);
      // enqueueSnackbar(e.message, { variant: "error" });
    }
  };

  const getUserPayment = async (new_user) => {
    try {
      const date = new Date();
      const month = date.getMonth() + 1;
      const year = date.getFullYear();
      const today = date.getDate();
      const previousMonth = month === 1 ? 12 : month - 1;

      const response = await app
        .firestore()
        .collection("payments")
        .where("user", "==", new_user.uid)
        .where("month", "in", [previousMonth, month])
        .where("year", "==", year)
        .get();

      const arr = [];
      for (let doc of response.docs) {
        arr.push({ id: doc.id, ...doc.data() });
      }
      if (month === 1) {
        // console.log("Month 1");
        const responsePrevYear = await app
          .firestore()
          .collection("payments")
          .where("user", "==", new_user.uid)
          .where("month", "in", [previousMonth])
          .where("year", "==", year - 1)
          .get();

        for (let doc of responsePrevYear.docs) {
          arr.push({ id: doc.id, ...doc.data() });
        }
      }
      const paidMonths = arr.filter((val) => val.month === month);
      const paidPrevMonths = arr.filter((val) => val.month === month - 1);
      if (today > 15) {
        // console.log("Today>15", paidMonths);
        return paidMonths;
      } else {
        // console.log(
        //   "Today<=15",
        //   paidMonths.length,
        //   paidMonths.length > 0 ? paidMonths : paidPrevMonths
        // );
        return paidMonths.length > 0 ? paidMonths : paidPrevMonths;
      }
    } catch (e) {
      // console.log(e.message);
      enqueueSnackbar(e.message, { variant: "error" });
    }
  };

  const uuidv4 = () => {
    return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(
      /[xy]/g,
      function (c) {
        var r = (Math.random() * 16) | 0,
          v = c === "x" ? r : (r & 0x3) | 0x8;
        return v.toString(16);
      }
    );
  };

  const updateUserCookie = async (user_id) => {
    try {
      const deviceId = uuidv4();
      await app
        .firestore()
        .collection("users")
        .doc(user_id)
        .update({ device_id: deviceId });
      removeCookie("device_id");
      setCookie("device_id", deviceId, {
        maxAge: 7890000,
        path: "/",
      });
      history.push("/main/dashboard");
    } catch (e) {
      console.log("Error writing document: ", e.message);
      enqueueSnackbar(e.message, { variant: "error" });
    }
  };

  const getInstitute = async (instituteId) => {
    try {
      const response = await app
        .firestore()
        .collection("institutes")
        .doc(instituteId)
        .get();
      const data = response.data();
      // console.log(data);
      return { id: response.id, ...data };
    } catch (e) {
      console.log(e.message);
      enqueueSnackbar(e.message, { variant: "error" });
    }
  };

  const signOut = () => {
    console.log('signout')
    app
      .auth()
      .signOut()
      .then(() => {
        setUser(null);
        history.push("/");
      })
      .catch((e) => {
        console.log(e.message);
        enqueueSnackbar(e.message, { variant: "error" });
      });
  };

  return (
    <AuthContext.Provider
      value={{
        user,
        role,
        profile,
        userPayments,
        institute,
        instituteData,
        initializing,
        loading,
        signIn,
        signOut,
        signUp,
        setInstituteData,
        updateUser,
        updateUserCredentials,
        getInstituteByCode,
        getUserByUname,
        updateUserAuthByAdmin,
        createUserByAdmin
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
