import React from "react";
// import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
// import Paper from "@material-ui/core/Paper";
import CoursesCardList from "../../../../components/CoursesCardList";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  useRouteMatch,
  // useLocation,
  // Redirect,
} from "react-router-dom";
import CourseDetails from "./CourseDetails/CourseDetails";
import PageTitle from "../../../../ui/PageTitle/PageTitle";
const useStyles = makeStyles((theme) => ({
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: "100vh",
    overflow: "auto",
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(1),
  },
  fixedHeight: {
    height: "100%",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
  },
}));

export default function Courses() {
  const classes = useStyles();

  // const fixedHeightPaper = clsx(classes.paper, classes.fixedHeight);
  let { path } = useRouteMatch();
  return (
    <main className={classes.content}>
      <div className={classes.appBarSpacer} />
      {/* <CustomBreadcrumbs /> */}
      <PageTitle title={"Courses"} />
      <Container maxWidth="lg" className={classes.container}>
        <Grid container spacing={3}>
          {/* <Router> */}
          <Switch>
            <Route exact path={`${path}/`}>
              <CoursesCardList />
            </Route>
            <Route path={`${path}/:course`}>
              <CourseDetails />
            </Route>
          </Switch>
          {/* </Router> */}
        </Grid>
      </Container>
    </main>
  );
}

// const PrivateCourseRoute = ({ children, ...rest }) => {
//   const { state } = useLocation();

//   return (
//     <Route
//       {...rest}
//       render={({ location }) =>
//         state && state.params ? (
//           children
//         ) : (
//           <Redirect
//             exact
//             from={location}
//             to={{
//               pathname: "/main/courses",
//             }}
//           />
//         )
//       }
//     />
//   );
// };
