import React, { useContext, useEffect, useState } from "react";
import app, { firebase } from "../../firebase/firebaseConfig";
import { AuthContext } from "../AuthContext/AuthContext";
// import { CourseContext } from "../CourseContext/CourseContext";
// import { StudentContext } from "../StudentContext/StudentContext";
import { useSnackbar } from "notistack";
import { CourseContext } from "../CourseContext/CourseContext";
export const PaymentContext = React.createContext({});

export const PaymentProvider = ({ children }) => {
  const { institute } = useContext(AuthContext);
  const { courses } = useContext(CourseContext);
  const [payments, setPayments] = useState(null);
  const [selectedStudentsByCourse, setSelectedStudentByCourse] = useState(null);
  const [selectedStudent, setSelectedStudent] = useState(null);
  const [selectedCourse, setSelectedCourse] = useState(
    courses && courses[0] && courses[0].id
  );
  const [selectedMonth, setSelectedMonth] = useState(new Date().getMonth() + 1);
  const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());
  const [initializing, setInitializing] = useState(true);
  const [paymentDataLoading, setPaymentDataLoading] = useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);
  const { enqueueSnackbar } = useSnackbar();

  const addPayment = (paymentObj) => {
    const { course, userObj, year, month } = paymentObj;
    const { id, username, fname, lname, nic } = userObj;
    // console.log(userObj);
    const obj = {
      user: id,
      username: username,
      fname: fname,
      lname: lname,
      nic: nic,
      year: year,
      month: month,
      course: course,
      institute: institute,
      createdAt: firebase.firestore.Timestamp.fromDate(new Date()),
    };
    // console.log(obj);
    const paymentDocId =
      `${institute}_${course}_${id}_${year}_${month}`.toString();
    app
      .firestore()
      .collection("payments")
      .doc(paymentDocId)
      .set(obj)
      .then(() => {
        setSuccess("Document written with ID: ");
        console.log("Document written with ID: ");
        enqueueSnackbar("Document written!", { variant: "success" });
      })
      .catch((e) => {
        setError(e.message);
        console.error("Error adding document: ", e);
        enqueueSnackbar(e.message, { variant: "error" });
      });
  };

  const deletePayment = (paymentId) => {
    app
      .firestore()
      .collection("payments")
      .doc(paymentId)
      .delete()
      .then(() => {
        setSuccess("Document successfully deleted!");
        console.log("Document successfully deleted!");
        enqueueSnackbar("Document successfully deleted!", {
          variant: "success",
        });
      })
      .catch((e) => {
        setError("Error removing document: ", e);
        console.error("Error removing document: ", e);
        enqueueSnackbar(e.message, { variant: "error" });
      });
  };

  useEffect(() => {
    const getPayments = () => {
      setPaymentDataLoading(true);

      app
        .firestore()
        .collection("payments")
        .where("institute", "==", institute)
        .where("course", "==", selectedCourse)
        .where("month", "==", selectedMonth)
        .where("year", "==", selectedYear)
        .onSnapshot((querySnapshot) => {
          const arr = [];

          querySnapshot.forEach((doc) => {
            arr.push({ id: doc.id, ...doc.data() });
          });

          setPayments(arr);
          setInitializing(false);
          setPaymentDataLoading(false);
        });
    };
    const getStudentsByCourse = () => {
      setPaymentDataLoading(true);
      app
        .firestore()
        .collection("users")
        .where("institute", "==", institute)
        .where("role", "==", 1)
        .where("courses", "array-contains", selectedCourse)
        .get()
        .then((querySnapshot) => {
          const arr = [];
          querySnapshot.forEach((doc) => {
            arr.push({ id: doc.id, ...doc.data() });
          });
          setSelectedStudentByCourse(arr);
          setInitializing(false);
          setPaymentDataLoading(false);
          console.log("Success");
          // enqueueSnackbar("Student data loaded successfully!", {
          //   variant: "success",
          // });
        })
        .catch((e) => {
          enqueueSnackbar(e.message, { variant: "error" });
        });
    };

    getPayments();
    getStudentsByCourse();
  }, [selectedCourse, selectedMonth, selectedYear, institute, enqueueSnackbar]);

  return (
    <PaymentContext.Provider
      value={{
        payments,
        error,
        initializing,
        success,
        selectedMonth,
        selectedYear,
        addPayment,
        deletePayment,
        setSelectedMonth,
        setSelectedYear,
        selectedStudent,
        selectedStudentsByCourse,
        setSelectedStudent,
        setSelectedCourse,
        selectedCourse,
        paymentDataLoading,
      }}
    >
      {children}
    </PaymentContext.Provider>
  );
};
