import React, { useContext, useState } from "react";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import TextInput from "../../../ui/TextInput/TextInput";
import { CourseContext } from "../../../context/CourseContext/CourseContext";
import { useLocation } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

export default function SectionForm(props) {
  const classes = useStyles();
  const { state } = useLocation();
  let { course } = state.params;
  const { addCourseModule, updateCourseModule } = useContext(CourseContext);
  const { title, description, action, setOpen, id } = props;
  const [ttl, setTitle] = useState(title ? title : "");
  const [desc, setDesc] = useState(description ? description : "");

  return (
    <form className={classes.form} noValidate>
      <Grid container spacing={2}>
        <Grid item xs={12} md={12} lg={12}>
          <TextInput
            label="Title"
            name="title"
            autoFocus={true}
            size="large"
            value={ttl}
            onChange={(e) => setTitle(e.target.value)}
            required={true}
            helperText="The title feild cannot be empty"
          />
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={12} md={12} lg={12}>
          <TextInput
            label="Decription"
            name="desc"
            size="large"
            value={desc}
            multiline={true}
            onChange={(e) => setDesc(e.target.value)}
            helperText="The description feild cannot be empty"
          />
        </Grid>
      </Grid>
      {/* <Divider variant="fullWidth" /> */}
      {action === 0 ? (
        <Button
          fullWidth
          variant="contained"
          color="primary"
          className={classes.submit}
          onClick={() => {
            ttl &&
              desc &&
              addCourseModule({
                course: course,
                title: ttl,
                description: desc,
                visibility: true,
              });
            setOpen(false);
          }}
        >
          Add
        </Button>
      ) : (
        <Button
          fullWidth
          variant="contained"
          color="primary"
          className={classes.submit}
          onClick={() => {
            ttl &&
              desc &&
              updateCourseModule(id, {
                title: ttl,
                description: desc,
              });
            setOpen(false);
          }}
        >
          Update
        </Button>
      )}
    </form>
  );
}
