import React, { useContext, useEffect, useState } from "react";
// import { useHistory, useLocation } from "react-router-dom";
import app, { firebase } from "../../firebase/firebaseConfig";
import { useSnackbar } from "notistack";

export const UsageContext = React.createContext({});

export const UsageProvider = ({ children }) => {

  const [selectedCourse, setSelectedCourse] = useState(null);
  const [selectedMonth, setSelectedMonth] = useState(null);
  const [selectedInstitute, setSelectedInstitute] = useState(null);

  const [databaseUsage, setDatabaseUsage] = useState(null);
  const [storageUsage, setStorageUsage] = useState(null);

  const [initializing, setInitializing] = useState(null);
  const [loading, setLoading] = useState(false);

  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    const getDatabaseUsage = () => {
      setInitializing(true);
      app
        .firestore()
        .collection("users")
        .where("institute", "==", selectedInstitute && selectedInstitute.id || "1")
        .where("role", "==", 0)
        .get()
        .then((querySnapshot) => {
          const arr = [];
          querySnapshot.forEach((doc) => {
            arr.push({ id: doc.id, ...doc.data() });
          });
          setDatabaseUsage(arr)
          setInitializing(false);
        })
        .catch((e) => {
          setInitializing(false)
          enqueueSnackbar(e.message, { variant: "error" });
        });
    };
    getDatabaseUsage();
  }, [selectedInstitute, selectedCourse, selectedMonth]);

  return (
    <UsageContext.Provider
      value={{
        loading,
        databaseUsage,
        selectedInstitute,
        setSelectedInstitute,
        selectedMonth,
        setSelectedMonth,
        selectedCourse,
        setSelectedCourse,
      }}
    >
      {children}
    </UsageContext.Provider>
  );
};
