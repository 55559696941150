import React from "react";
import ReactDOM from "react-dom";
import Routes from "./routes";
import * as serviceWorker from "./serviceWorker";
import { CookiesProvider } from "react-cookie";
import { SnackbarProvider } from "notistack";
import { CustomThemeProvider } from "./context/CustomThemeContext/CustomThemeContext";
import { AppProvider } from "./context/AppContext/AppContext";
import './index.css'

ReactDOM.render(
  <React.StrictMode>
    <SnackbarProvider
      maxSnack={3}
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
    >
      <CookiesProvider>
        <CustomThemeProvider>
          <AppProvider>
            <Routes />
          </AppProvider>
        </CustomThemeProvider>
      </CookiesProvider>
    </SnackbarProvider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register();
