import React, { useContext, useState } from "react";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
// import { useHistory } from "react-router-dom";
import SelectMenu from "../../ui/SelectMenu/SelectMenu";
import TextInput from "../../ui/TextInput/TextInput";
import ImageUpload from "../../ui/ImageUpload/ImageUpload";
import CustomTimePicker from "../../ui/CustomTimePicker/CustomTimePicker";
import { Days } from "../../constants/Days";
import { InstituteContext } from "../../context/InstituteContext/InstituteContext";
import { CircularProgress } from "@material-ui/core";
import { CourseCategories } from "../../constants/CourseCategories";
import { Mediums } from "../../constants/Mediums";
import { CourseContext } from "../../context/CourseContext/CourseContext";
import { AdministratorContext } from "../../context/AdministratorContext/AdministratorContext";
import { validate } from "../../utils/utils";

import schema from "./adminValidateScheme";
import { AuthContext } from "../../context/AuthContext/AuthContext";
// import { Typography } from "@material-ui/core";
// import CloudUploadIcon from "@material-ui/icons/CloudUpload";

const useStyles = makeStyles((theme) => ({
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

export default function AdministratorForm(props) {
  const classes = useStyles();

  const { addAdministrator, updateAdministrator, selectedAdministrator, selectedInstitute, loading } = useContext(AdministratorContext);
  const { createUserByAdmin } = useContext(AuthContext)
  const { action, setOpen } = props;
  const [imageFiles, setImageFiles] = useState(null);
  const [image, setImage] = useState(
    action === 1 && selectedAdministrator && selectedAdministrator.image ? selectedAdministrator.image : ""
  );
  const [username, setUsername] = useState(
    action === 1 && selectedAdministrator && selectedAdministrator.username ? selectedAdministrator.username : ""
  );
  const [password, setPassword] = useState("");
  const [error, setError] = useState({});
  const [id, setID] = useState(
    action === 1 && selectedAdministrator && selectedAdministrator.id ? selectedAdministrator.id : ""
  );

  let OnSubmit = async (e) => {
    e.preventDefault();
    const newErrorObject = validate(
      { username: username, password: password },
      schema
    );
    if (Object.keys(newErrorObject).length > 0) {
      setError(newErrorObject);
      return;
    }

    if (action === 1) {
      await updateAdministrator(selectedInstitute.id, { image });
    } else {
      let response = await createUserByAdmin({ email: username, password: password })
      if (response) {
        await addAdministrator({ username, image });
      }
    }
    setOpen(false)
  };

  return (
    <form
      onSubmit={OnSubmit}
      className={classes.form}
      noValidate
    >
      <Grid container spacing={2}>
        <Grid item container xs={12} md={12} lg={12}>
          <Grid item xs={12} md={3} lg={3}>
            <ImageUpload
              setImageFiles={setImageFiles}
              image={image}
              width={200}
              height={200}
              maxSize={5000000}
            />
          </Grid>
          <Grid item container xs={12} md={9} lg={9}>
            {action === 1 &&
              <Grid item xs={12} md={12} lg={12}>
                <TextInput
                  value={id}
                  onChange={(e) => setID(e.target.value)}
                  label="Administrator ID"
                  name="id"
                  disabled={true}
                />
              </Grid>}
            <Grid item xs={12} md={12} lg={12}>
              <TextInput
                value={username}
                onChange={(e) => setUsername(e.target.value)}
                label="Administrator Username"
                name="uname"
                disabled={action === 1}
                error={error.username}
                onFocus={() => delete error["username"]}
                onBlur={() =>
                  setError({ ...error, ...validate({ username: username }, schema) })
                }
                helperText={error.username}
              />
            </Grid>
            {action === 0 &&
              <Grid item xs={12} md={12} lg={12}>
                <TextInput
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  label="Administrator Password"
                  name="password"
                  disabled={action === 1}
                  error={error.password}
                  onFocus={() => delete error["password"]}
                  onBlur={() =>
                    setError({ ...error, ...validate({ password: password }, schema) })
                  }
                  helperText={error.password}
                />
              </Grid>
            }

          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={12} md={12} lg={12}></Grid>
        </Grid>
      </Grid>

      <Grid container spacing={2}>

      </Grid>
      <Button
        startIcon={loading && <CircularProgress size={20} />}
        fullWidth
        variant="contained"
        color="primary"
        className={classes.submit}
        // onClick={OnSubmit}
        type="submit"
        disabled={loading}
      >
        {action === 1 ? "Update Administrator" : "Add Administrator"}
      </Button>
    </form>
  );
}
