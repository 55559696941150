import React, { useContext, useState } from "react";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
// import { useHistory } from "react-router-dom";
import SelectMenu from "../../ui/SelectMenu/SelectMenu";
import TextInput from "../../ui/TextInput/TextInput";
import CustomDatePicker from "../../ui/CustomDatePicker/CustomDatePicker";
import { AuthContext } from "../../context/AuthContext/AuthContext";
import { IconButton, InputAdornment } from "@material-ui/core";
import { Visibility, VisibilityOff } from "@material-ui/icons";
import { validate } from "../../utils/utils";
import schema from "./registerValidateScheme";
// import { Typography } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

export default function StudentRegisterForm(props) {
  const classes = useStyles();

  const {
    role,
    signUp,
    profile,
    initializing,
    updateUser,
    updateUserCredentials,
    getInstituteByCode,
  } = useContext(AuthContext);

  const { action, setOpen } = props;
  const [fname, setFname] = useState(
    action === 1 && profile && profile.fname ? profile.fname : ""
  );
  const [lname, setLname] = useState(
    action === 1 && profile && profile.lname ? profile.lname : ""
  );
  const [uname, setUname] = useState(
    action === 1 && profile && profile.username ? profile.username : ""
  );
  const [contact, setContact] = useState(
    action === 1 && profile && profile.contact ? profile.contact : ""
  );
  const [nic, setNic] = useState(
    action === 1 && profile && profile.nic ? profile.nic : ""
  );
  const [gender, setGender] = useState(
    action === 1 && profile && profile.gender ? profile.gender : 0
  );

  const dateNow = new Date();
  dateNow.setFullYear(dateNow.getFullYear() - 15);
  const [dob, setDob] = useState(
    action === 1 && profile && profile.dob
      ? new Date(profile.dob.toDate())
      : dateNow
  );
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [instituteCode, setInstituteCode] = useState(
    action === 1 && profile && profile.instituteCode
      ? profile.instituteCode
      : ""
  );
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [email, setEmail] = useState(
    action === 1 && profile && profile.email ? profile.email : ""
  );
  const [error, setError] = useState({});

  let OnSubmit = async (e) => {
    e.preventDefault();
    const newErrorObject = validate(
      {
        username: uname.toLowerCase(),
        "first name": fname,
        "last name": lname,
        nic: nic,
        password: password,
        "confirm password": confirmPassword,
        "institute code": instituteCode,
        contact: contact,
      },
      schema
    );
    if (Object.keys(newErrorObject).length > 0) {
      // console.log(newErrorObject);
      setError(newErrorObject);
      return;
    }

    const instituteDetails = await getInstituteByCode(instituteCode);
    // console.log(instituteDetails, "get institute");
    if (instituteDetails) {
      const obj = {
        username: `${uname}@${instituteCode.toLowerCase()}.com`,
        fname: fname,
        lname: lname,
        nic: nic,
        gender: gender,
        dob: dob,
        email: email,
        password: password,
        institute: instituteDetails.institute,
        instituteCode: instituteCode,
        contact: contact,
        role: 1,
        device_id: "",
      };
      signUp(obj);
    }
  };

  let OnUpdateProfile = (e) => {
    const newErrorObject = validate(
      {
        "first name": fname,
        "last name": lname,
      },
      schema
    );
    if (Object.keys(newErrorObject).length > 0) {
      // console.log(newErrorObject);
      setError(newErrorObject);
      return;
    }

    const obj = {
      fname: fname,
      lname: lname,
      email: email,
      dob: dob,
    };
    updateUser(obj, setOpen);
  };
  let OnUpdatePassword = (e) => {
    updateUserCredentials(password, setOpen);
  };

  return (
    <form onSubmit={OnSubmit} className={classes.form}>
      <Grid container spacing={2}>
        <Grid item xs={6} md={6} lg={6}>
          <TextInput
            value={fname}
            onChange={(e) =>
              setFname(e.target.value ? e.target.value.trim() : e.target.value)
            }
            error={error["first name"]}
            // error={!/^[a-zA-Z]+$/g.test(fname)}
            label="First Name"
            name="fname"
            onFocus={() => delete error["first name"]}
            onBlur={() =>
              setError({
                ...error,
                ...validate({ "first name": fname }, schema),
              })
            }
            helperText={error["first name"]}
            autoFocus={true}
          />
        </Grid>
        <Grid item xs={6} md={6} lg={6}>
          <TextInput
            value={lname}
            onChange={(e) =>
              setLname(e.target.value ? e.target.value.trim() : e.target.value)
            }
            error={error["last name"]}
            label="Last Name"
            name="lname"
            onFocus={() => delete error["last name"]}
            onBlur={() =>
              setError({
                ...error,
                ...validate({ "last name": lname }, schema),
              })
            }
            helperText={error["last name"]}
          />
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={12} md={12} lg={12}>
          <TextInput
            value={instituteCode}
            onChange={(e) =>
              setInstituteCode(
                e.target.value ? e.target.value.toUpperCase() : e.target.value
              )
            }
            // error={!/^[A-Z0-9]{6}$/g.test(instituteCode)}
            error={error["institute code"]}
            label="Institute Code"
            name="instituteCode"
            disabled={action === 1}
            onFocus={() => delete error["institute code"]}
            onBlur={() =>
              setError({
                ...error,
                ...validate({ "institute code": instituteCode }, schema),
              })
            }
            helperText={error["institute code"]}
          />
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={12} md={12} lg={12}>
          <TextInput
            value={uname}
            onChange={(e) => setUname(e.target.value)}
            // error={!/^[a-zA-Z0-9_@]{6,}$/g.test(uname)}
            error={error["username"]}
            label="Username"
            name="uname"
            InputProps={{
              endAdornment: action !== 1 && (
                <InputAdornment position="end">
                  @{instituteCode ? instituteCode.toLowerCase() : instituteCode}
                  .com
                </InputAdornment>
              ),
            }}
            disabled={action === 1}
            onFocus={() => delete error["username"]}
            onBlur={() =>
              setError({
                ...error,
                ...validate({ username: uname }, schema),
              })
            }
            helperText={error["username"]}
          />
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={6} md={6} lg={6}>
          <TextInput
            value={contact}
            onChange={(e) => setContact(e.target.value)}
            // error={!/^[0-9]{9}$/g.test(contact)}
            error={error["contact"]}
            label="Contact Number"
            name="contact"
            type="number"
            disabled={action === 1}
            onFocus={() => delete error["contact"]}
            onBlur={() =>
              setError({
                ...error,
                ...validate({ contact: contact }, schema),
              })
            }
            helperText={error["contact"]}
          />
        </Grid>

        <Grid item xs={6} md={6} lg={6}>
          <TextInput
            value={nic}
            type="number"
            // error={!/^[0-9]{9,13}$/g.test(nic)}
            error={error["nic"]}
            onChange={(e) => setNic(e.target.value)}
            label="National Identity Card"
            name="nic"
            disabled={action === 1}
            onFocus={() => delete error["nic"]}
            onBlur={() =>
              setError({
                ...error,
                ...validate({ nic: nic }, schema),
              })
            }
            helperText={error["nic"]}
          />
        </Grid>
      </Grid>

      <Grid container spacing={2}>
        <Grid item xs={12} md={12} lg={12}>
          <TextInput
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            // error={!/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g.test(email)}
            // error={error["email"]}
            label="Email"
            name="email"
          // disabled={action === 1}
          // onFocus={() => delete error["email"]}
          // onBlur={() =>
          //   setError({
          //     ...error,
          //     ...validate({ email: email }, schema),
          //   })
          // }
          // helperText={error["email"]}
          />
        </Grid>
      </Grid>

      <Grid container spacing={2}>
        <Grid item xs={6} md={6} lg={6}>
          <CustomDatePicker
            value={dob}
            onChange={(e) => setDob(e)}
            size="large"
            label="Date of Birth"
            maxDate={dateNow}
          />
        </Grid>
        <Grid item xs={6} md={6} lg={6}>
          <SelectMenu
            variant="outlined"
            size="large"
            onChange={(e) => setGender(e.target.value)}
            data={[
              { value: 0, label: "Male" },
              { value: 1, label: "Female" },
            ]}
            label="Gender"
            fullWidth={true}
            value={gender}
            disabled={action === 1}
          />
        </Grid>
      </Grid>

      <Grid container spacing={2}>
        <Grid
          item
          xs={action === 1 ? 4 : 6}
          md={action === 1 ? 4 : 6}
          lg={action === 1 ? 4 : 6}
        >
          <TextInput
            type={showPassword ? "text" : "password"}
            // helperText={
            //   "Password must minimum 8 characters, at least one uppercase letter, one lowercase letter, one number and one special character."
            // }
            label={action === 1 ? "New Password" : "Password"}
            name="pwd"
            value={password}
            // error={
            //   !/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$_!%*?&])[A-Za-z\d@$!%*_?&]{8,}$/.test(
            //     password
            //   )
            // }
            error={error["password"]}
            onFocus={() => delete error["password"]}
            onBlur={() =>
              setError({
                ...error,
                ...validate({ password: password }, schema),
              })
            }
            helperText={error["password"]}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onMouseDown={() => setShowPassword(true)}
                    onMouseUp={() => setShowPassword(false)}
                    edge="end"
                  >
                    {showPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
            onChange={(e) => setPassword(e.target.value)}
          />
        </Grid>
        <Grid
          item
          xs={action === 1 ? 4 : 6}
          md={action === 1 ? 4 : 6}
          lg={action === 1 ? 4 : 6}
        >
          <TextInput
            type={showConfirmPassword ? "text" : "password"}
            label="Confirm Password"
            name="cpwd"
            value={confirmPassword}
            error={error["confirm password"]}
            onFocus={() => delete error["confirm password"]}
            // onBlur={() =>
            //   setError({
            //     ...error,
            //     ...validate({ "confirm password": confirmPassword }, schema),
            //   })
            // }
            helperText={error["confirm password"]}
            onChange={(e) => setConfirmPassword(e.target.value)}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    // onClick={() => setShowConfirmPassword(true)}
                    onMouseUp={() => setShowConfirmPassword(false)}
                    onMouseDown={() => setShowConfirmPassword(true)}
                    edge="end"
                  >
                    {showConfirmPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </Grid>
        {action === 1 && (
          <Grid item xs={12} md={4} lg={4}>
            <Button
              fullWidth
              variant="contained"
              color="secondary"
              style={{ height: "100%" }}
              onClick={OnUpdatePassword}
            >
              Update Password
            </Button>
          </Grid>
        )}
        {action === 0 ? (
          <Button
            disabled={initializing}
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
            // onClick={OnSubmit}
            type="submit"
          >
            Register Now
          </Button>
        ) : (
          <Button
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
            onClick={OnUpdateProfile}
          >
            Update Profile
          </Button>
        )}
      </Grid>
    </form>
  );
}
