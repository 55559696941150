import React, { useContext, useEffect, useState } from "react";
// import { useHistory, useLocation } from "react-router-dom";
import app, { firebase, storage } from "../../firebase/firebaseConfig";
import { AuthContext } from "../AuthContext/AuthContext";
// import axios from "axios";
import { useSnackbar } from "notistack";
import { Days } from "../../constants/Days";
import moment from "moment";
export const CourseContext = React.createContext({});

export const CourseProvider = ({ children }) => {
  const [courses, setCourses] = useState(null);
  const [coursesTableData, setCoursesTableData] = useState(null);
  const [selectedCourse, setSelectedCourse] = useState(null);
  const [courseModules, setCourseModules] = useState(null);
  const [selectedCourseModule, setSelectedCourseModule] = useState(null);
  const [courseModuleContents, setCourseModuleContents] = useState(null);
  const [initializing, setInitializing] = useState(true);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);
  const [uploadingProgress, setUploadingProgress] = useState(0);
  const { institute } = useContext(AuthContext);
  const { enqueueSnackbar } = useSnackbar();

  const [selectedTempCourse, setSelectedTempCourse] = useState(null);
  const [tempCourseModules, setTempCourseModules] = useState(null);
  const [selectedTempCourseModule, setSelectedTempCourseModule] = useState(null);
  const [tempCourseModuleContents, setTempCourseModuleContents] = useState(null);
  const [selectedTempCourseModuleContent, setSelectedTempCourseModuleContent] = useState(null);

  useEffect(() => {
    getCourseModulesOnce(selectedTempCourse)
  }, [selectedTempCourse])

  useEffect(() => {
    getCourseModuleContentsOnce(selectedTempCourseModule)
  }, [selectedTempCourseModule])

  useEffect(() => {
    const getCourses = () => {
      setLoading(true);
      const unsubscribe = app
        .firestore()
        .collection("courses")
        .where("institute", "==", institute)
        .orderBy("createdAt")
        .onSnapshot(
          (querySnapshot) => {
            const arr = [];
            const tableData = [];
            querySnapshot.forEach((doc) => {
              let obj = { id: doc.id, ...doc.data() };
              obj["startTime"] = moment(new Date(doc.data().startTime.toDate()))
                .format("LT")
                .toString();
              obj["endTime"] = moment(new Date(doc.data().endTime.toDate()))
                .format("LT")
                .toString();
              obj["day"] = Days[obj["day"] - 1];

              arr.push({ id: doc.id, ...doc.data() });
              tableData.push(obj);
            });
            setCourses(arr);
            setCoursesTableData(tableData);
            setInitializing(false);
            setLoading(false);
            // enqueueSnackbar("Documents loaded!", { variant: "success" });
          },
          (e) => {
            console.log(e.message);
            enqueueSnackbar(e.message, { variant: "error" });
          }
        );
      return () => unsubscribe();
    };
    getCourses();
  }, [institute]);

  const uploadFileAsync = async (imageFile, path) => {
    try {
      const uploadTask = await storage
        .child(`${institute}/${path}/${imageFile.name}`)
        .put(imageFile);
      // console.log(uploadTask);
      const downloadURL = await uploadTask.ref.getDownloadURL();
      enqueueSnackbar("File uploaded!", { variant: "success" });
      return downloadURL;
    } catch (e) {
      console.error("Error adding document: ", e);
      enqueueSnackbar(e.message, { variant: "error" });
    }
  };

  const addCourse = async (courseObj, callback) => {
    try {
      courseObj["startTime"] = firebase.firestore.Timestamp.fromDate(
        courseObj["startTime"]
      );
      courseObj["endTime"] = firebase.firestore.Timestamp.fromDate(
        courseObj["endTime"]
      );

      const downloadURL = courseObj.imageFiles
        ? await uploadFileAsync(courseObj.imageFiles[0], "courses")
        : "";

      courseObj["image"] = downloadURL;

      delete courseObj.imageFiles;

      const response = await app
        .firestore()
        .collection("courses")
        .add({
          institute: institute,
          ...courseObj,
          createdAt: firebase.firestore.Timestamp.fromDate(new Date()),
          updatedAt: firebase.firestore.Timestamp.fromDate(new Date())
        });
      // console.log(response);
      callback(false);
      enqueueSnackbar("Document written!", { variant: "success" });
    } catch (e) {
      setError(e.message);
      console.error("Error adding document: ", e);
      enqueueSnackbar(e.message, { variant: "error" });
    }
  };

  const updateCourse = async (courseId, courseObj, callback) => {
    setLoading(true);
    try {
      courseObj["startTime"] = firebase.firestore.Timestamp.fromDate(
        courseObj["startTime"]
      );
      courseObj["endTime"] = firebase.firestore.Timestamp.fromDate(
        courseObj["endTime"]
      );

      const downloadURL = courseObj.imageFiles
        ? await uploadFileAsync(courseObj.imageFiles[0], "courses")
        : courseObj["image"];

      courseObj["image"] = downloadURL;

      delete courseObj.imageFiles;

      const response = await app
        .firestore()
        .collection("courses")
        .doc(courseId)
        .update({ ...courseObj, updatedAt: firebase.firestore.Timestamp.fromDate(new Date()) });

      setSuccess("Document successfully written!");
      // console.log("Document successfully written!");
      callback(false);
      enqueueSnackbar("Document successfully written!", {
        variant: "success",
      });
      setLoading(false);
    } catch (e) {
      console.error("Error writing document: ", e);
      enqueueSnackbar(e.message, { variant: "error" });
      setLoading(false);
    }
  };

  const deleteCourse = (courseId) => {
    app
      .firestore()
      .collection("courseModules")
      .where("course", "==", courseId)
      .get()
      .then((querySnapshot) => {
        const arr = [];
        querySnapshot.forEach((doc) => {
          arr.push({ id: doc.id, ...doc.data() });
        });
        if (arr.length === 0) {
          app
            .firestore()
            .collection("courses")
            .doc(courseId)
            .delete()
            .then(() => {
              setSuccess("Document successfully deleted!");
              // console.log("Document successfully deleted!");
              enqueueSnackbar("Document successfully deleted!", {
                variant: "success",
              });
            })
            .catch((e) => {
              setError("Error removing document: ", e);
              console.error("Error removing document: ", e);
              enqueueSnackbar(e.message, { variant: "error" });
            });
        } else {
          enqueueSnackbar("Cannot delete a course with course modules", {
            variant: "error",
          });
        }
      })
      .catch((e) => {
        enqueueSnackbar(e.message, { variant: "error" });
      });
  };

  const getCourseModules = (courseId) => {
    setLoading(true);
    selectedCourse?.();
    const unsubscribe = app
      .firestore()
      .collection("courseModules")
      .where("course", "==", courseId)
      .orderBy("createdAt")
      .onSnapshot(
        (querySnapshot) => {
          const arr = [];
          querySnapshot.forEach((doc) => {
            arr.push({ id: doc.id, ...doc.data() });
          });
          // console.log(arr);
          setCourseModules(arr);
          setLoading(false);
          setSelectedCourse(() => unsubscribe);
        },
        (e) => {
          console.log(e.message);
          enqueueSnackbar(e.message, { variant: "error" });
        }
      );
  };

  const getCourseModulesOnce = (courseId) => {
    app
      .firestore()
      .collection("courseModules")
      .where("course", "==", courseId)
      .orderBy("createdAt")
      .get()
      .then(
        (querySnapshot) => {
          const arr = [];
          querySnapshot.forEach((doc) => {
            arr.push({ id: doc.id, ...doc.data() });
          });
          // console.log(arr);
          setTempCourseModules(arr);
          setSelectedTempCourseModule(null)
        }
      ).catch(e => {
        setTempCourseModules(null);
        setSelectedTempCourseModule(null)
        console.log(e.message);
        enqueueSnackbar(e.message, { variant: "error" });
      });
  };

  const addCourseModule = (courseModuleObj) => {
    app
      .firestore()
      .collection("courseModules")
      .add({
        institute: institute,
        ...courseModuleObj,
        createdAt: firebase.firestore.Timestamp.fromDate(new Date()),
        updatedAt: firebase.firestore.Timestamp.fromDate(new Date())
      })
      .then((docRef) => {
        setSuccess("Document written with ID: ", docRef.id);
        // console.log("Document written with ID: ", docRef.id);
        enqueueSnackbar("Document written!", { variant: "success" });
      })
      .catch((e) => {
        setError(e.message);
        console.error("Error adding document: ", e);
        enqueueSnackbar(e.message, { variant: "error" });
      });
  };

  const updateCourseModule = (courseModuleId, courseModuleObj) => {
    app
      .firestore()
      .collection("courseModules")
      .doc(courseModuleId)
      .update({ ...courseModuleObj, updatedAt: firebase.firestore.Timestamp.fromDate(new Date()) })
      .then(() => {
        setSuccess("Document successfully written!");
        // console.log("Document successfully written!");
        enqueueSnackbar("Document successfully written!", {
          variant: "success",
        });
        // console.log(courseModuleObj);
        // getCourseModules(courseModuleObj.course);
      })
      .catch((e) => {
        setError("Error writing document: ", e);
        console.error("Error writing document: ", e);
        enqueueSnackbar(e.message, { variant: "error" });
      });
  };

  const deleteCourseModule = (courseModuleId) => {
    app
      .firestore()
      .collection("courseModuleContents")
      .where("courseModule", "==", courseModuleId)
      .get()
      .then((querySnapshot) => {
        const arr = [];
        querySnapshot.forEach((doc) => {
          arr.push({ id: doc.id, ...doc.data() });
        });
        if (arr.length === 0) {
          app
            .firestore()
            .collection("courseModules")
            .doc(courseModuleId)
            .delete()
            .then(() => {
              setSuccess("Document successfully deleted!");
              // console.log("Document successfully deleted!");
              enqueueSnackbar("Document successfully deleted!", {
                variant: "success",
              });
            })
            .catch((e) => {
              setError("Error removing document: ", e);
              console.error("Error removing document: ", e);
              enqueueSnackbar(e.message, { variant: "error" });
            });
        } else {
          enqueueSnackbar("Cannot delete course module with contents", {
            variant: "error",
          });
        }
      })
      .catch((e) => {
        enqueueSnackbar(e.message, { variant: "error" });
      });
  };

  const getCourseModuleContents = (courseModuleId) => {
    // console.log("1CHangesd");
    setLoading(true);
    selectedCourseModule?.();
    const unsubscribe = app
      .firestore()
      .collection("courseModuleContents")
      .where("courseModule", "==", courseModuleId)
      .orderBy("createdAt")
      .onSnapshot(
        (querySnapshot) => {
          // console.log("2CHangesd");
          const arr = [];
          querySnapshot.forEach((doc) => {
            arr.push({ id: doc.id, ...doc.data() });
          });
          // console.log(courseModuleId, arr);
          setCourseModuleContents(arr);
          setLoading(false);
          setSelectedCourseModule(() => unsubscribe);
        },
        (e) => {
          console.log(e.message);
          enqueueSnackbar(e.message, { variant: "error" });
        }
      );
  };

  const getCourseModuleContentsOnce = (courseModuleId) => {
    app
      .firestore()
      .collection("courseModuleContents")
      .where("courseModule", "==", courseModuleId)
      .orderBy("createdAt")
      .get()
      .then(
        (querySnapshot) => {
          // console.log("2CHangesd");
          const arr = [];
          querySnapshot.forEach((doc) => {
            arr.push({ id: doc.id, ...doc.data() });
          });
          setTempCourseModuleContents(arr);
          setSelectedTempCourseModuleContent(null)
        })
      .catch(e => {
        setTempCourseModuleContents(null);
        setSelectedTempCourseModuleContent(null)
        console.log(e.message);
        enqueueSnackbar(e.message, { variant: "error" });
      });
  };

  const uploadFile = async (
    courseModuleContentObj,
    handleOpen,
    handleUploadingProgress
  ) => {
    const { file, type } = courseModuleContentObj;

    // const metadata = {
    //   contentType: type === 1 ? "video" : "doc",
    // };
    const uploadTask = storage
      .child(`${institute}/${type === 1 ? "video" : "doc"}/${file[0].name}`)
      .put(file[0]);
    uploadTask.on(
      "state_changed",
      (snapshot) => {
        const currentProgress =
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        setUploadingProgress(currentProgress);
        switch (snapshot.state) {
          case firebase.storage.TaskState.PAUSED:
            console.log("Upload is paused");
            break;
          case firebase.storage.TaskState.RUNNING:
            console.log("Upload is running");
            break;
          default:
            console.log("nothing");
        }
      },
      (e) => {
        console.log(e.message);
        enqueueSnackbar(e.message, { variant: "error" });
      },
      () => {
        uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
          console.log("File available at", downloadURL);
          courseModuleContentObj.file = downloadURL;
          app
            .firestore()
            .collection("courseModuleContents")
            .add({
              ...courseModuleContentObj,
              createdAt: firebase.firestore.Timestamp.fromDate(new Date()),
            })
            .then((docRef) => {
              setSuccess("Document written with ID: ", docRef.id);
              handleOpen(false);
              handleUploadingProgress(0);
              console.log("Document written with ID: ", docRef.id);
              enqueueSnackbar("Document written!", { variant: "success" });
            })
            .catch((e) => {
              setError(e.message);
              console.error("Error adding document: ", e);
              enqueueSnackbar(e.message, { variant: "error" });
            });
        });
      }
    );
  };

  // const uploadVideo = async (files, resolution, fps) => {
  //   const formData = new FormData();
  //   const height = resolution[1];
  //   const width = resolution[0];
  //   formData.append("file", files[0]);
  //   formData.append(
  //     "convert",
  //     new Blob(
  //       [
  //         JSON.stringify({
  //           height,
  //           width,
  //           fps,
  //         }),
  //       ],
  //       {
  //         type: "application/json",
  //       }
  //     )
  //   );
  //   const response = await axios({
  //     method: "post",
  //     url: `https://stream.dhahas.com/api/addFile`,
  //     data: formData,
  //   });
  //   console.log(response);
  // };

  const addCourseModuleContent = async (
    type,
    courseModuleContentObj,
    setOpen
  ) => {
    if (courseModuleContentObj['createdAt']) {
      courseModuleContentObj['createdAt'] = firebase.firestore.Timestamp.fromDate(courseModuleContentObj['createdAt'])
    }
    if (type === 0 || type === 4) {
      app
        .firestore()
        .collection("courseModuleContents")
        .add({
          ...courseModuleContentObj,
          updatedAt: firebase.firestore.Timestamp.fromDate(new Date())
        })
        .then((docRef) => {
          setSuccess("Document written with ID: ", docRef.id);
          setOpen(false);
          console.log("Document written with ID: ", docRef.id);
          enqueueSnackbar("Document written!", { variant: "success" });
        })
        .catch((e) => {
          setError(e.message);
          console.error("Error adding document: ", e);
          enqueueSnackbar(e.message, { variant: "error" });
        });
    } else if (type === 1 || type === 2 || type === 3) {
      await uploadFile(courseModuleContentObj, setOpen, setUploadingProgress);
    }
  };

  const checkAccessToCourseModuleContent = async (
    courseModuleContentId,
    userId
  ) => {
    const response = await app
      .firestore()
      .collection("studentCourseModuleContents")
      .doc(`${courseModuleContentId}_${userId}`)
      .get();

    const data = await response.data();
    return data;
  };

  const updateStudentCourseModuleContent = (
    courseModuleContentId,
    userId,
    obj
  ) => {
    app
      .firestore()
      .collection("studentCourseModuleContents")
      .doc(`${courseModuleContentId}_${userId}`)
      .set({ ...obj, updatedAt: firebase.firestore.Timestamp.fromDate(new Date()) })
      .then(() => { })
      .catch((e) => { });
  };

  const updateCourseModuleContent = (
    courseModuleContentId,
    courseModuleContentObj,
    setOpen
  ) => {
    if (courseModuleContentObj['createdAt']) {
      courseModuleContentObj['createdAt'] = firebase.firestore.Timestamp.fromDate(courseModuleContentObj['createdAt'])
    }
    app
      .firestore()
      .collection("courseModuleContents")
      .doc(courseModuleContentId)
      .update({ ...courseModuleContentObj, updatedAt: firebase.firestore.Timestamp.fromDate(new Date()) })
      .then(() => {
        setSuccess("Document successfully written!");
        setOpen(false);
        console.log("Document successfully written!");
        enqueueSnackbar("Document successfully written!", {
          variant: "success",
        });
        // console.log(courseModuleContentObj);
        // getCourseModuleContents()
      })
      .catch((e) => {
        setError("Error writing document: ", e);
        console.error("Error writing document: ", e);
        enqueueSnackbar(e.message, { variant: "error" });
      });
  };

  const deleteCourseModuleContentStorage = async (url, type) => {
    try {
      if (type === 1 || type === 2 || type === 3) {
        var fileRef = firebase.storage().refFromURL(url);
        await fileRef.delete()
        enqueueSnackbar("File deleted!", {
          variant: "success",
        });
      }
    } catch (err) {
      enqueueSnackbar(err.message, { variant: "error" });
    }
  }

  const deleteCourseModuleContent = async (courseModuleContentId, url, type, contentType) => {
    try {
      console.log(contentType)
      if (contentType && contentType !== 4) {
        await deleteCourseModuleContentStorage(url, type)
      }

      await app
        .firestore()
        .collection("courseModuleContents")
        .doc(courseModuleContentId)
        .delete()
      enqueueSnackbar("Document successfully deleted!", {
        variant: "success",
      });
    } catch (err) {
      enqueueSnackbar(err.message, { variant: "error" });
    }

  };

  return (
    <CourseContext.Provider
      value={{
        courses,
        coursesTableData,
        courseModules,
        courseModuleContents,
        error,
        initializing,
        loading,
        uploadingProgress,
        success,
        selectedTempCourse,
        tempCourseModules,
        selectedTempCourseModule,
        tempCourseModuleContents,
        selectedTempCourseModuleContent,
        addCourse,
        updateCourse,
        deleteCourse,
        getCourseModules,
        addCourseModule,
        updateCourseModule,
        deleteCourseModule,
        getCourseModuleContents,
        addCourseModuleContent,
        updateCourseModuleContent,
        deleteCourseModuleContent,
        updateStudentCourseModuleContent,
        checkAccessToCourseModuleContent,
        setSelectedTempCourse,
        setSelectedTempCourseModule,
        setSelectedTempCourseModuleContent
      }}
    >
      {children}
    </CourseContext.Provider>
  );
};
