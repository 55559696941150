import React, { useContext } from "react";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import List from "@material-ui/core/List";
import Divider from "@material-ui/core/Divider";
import IconButton from "@material-ui/core/IconButton";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import { Switch, Route, useHistory } from "react-router-dom";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import DashboardIcon from "@material-ui/icons/Dashboard";
import MenuBookOutlinedIcon from "@material-ui/icons/MenuBookOutlined";
import GroupOutlinedIcon from "@material-ui/icons/GroupOutlined";
import CreditCard from "@material-ui/icons/CreditCard";
// import { Avatar, Typography } from "@material-ui/core";

import Dashboard from "./Dashboard/Dashboard";
import Payment from "./Payment/Payment";
import Student from "./Student/Student";
import Courses from "./Courses/Courses";
import { AuthContext } from "../../../context/AuthContext/AuthContext";
import { CourseProvider } from "../../../context/CourseContext/CourseContext";
import { StudentProvider } from "../../../context/StudentContext/StudentContext";
import { PaymentProvider } from "../../../context/PaymentContext/PaymentContext";
import { Avatar, CircularProgress, ListItemAvatar, Typography } from "@material-ui/core";
import { toTitleCase } from "../../../utils/utils";
import Institutes from "./Institute/Institute";
import Administrators from "./Administrators/Administrators";
import Billing from "./Billing/Billing";
import { Business, DataUsage, MonetizationOn, SupervisorAccount } from "@material-ui/icons";
import { InstituteProvider } from "../../../context/InstituteContext/InstituteContext";
import { AdministratorProvider } from "../../../context/AdministratorContext/AdministratorContext";
import Usage from "./Usage/Usage";
import { UsageProvider } from "../../../context/UsageContext/UsageContext";

const routes = [
  {
    path: "/main/dashboard",
    main: () => <Dashboard />,
    role: [0, 1, 2]
    // exact: true,
  },
  {
    path: "/main/courses",
    main: () => <Courses />,
    role: [0, 1, 2]
  },
  {
    path: "/main/student",
    main: () => <Student />,
    role: [0, 1, 2]
    // exact: true,
  },
  {
    path: "/main/payment",
    main: () => <Payment />,
    role: [0, 1, 2]
    // exact: true,
  },
];

const superAdminRoutes = [
  {
    path: "/main/institute",
    main: () => <Institutes />,
    role: [3]
    // exact: true,
  },
  {
    path: "/main/administrator",
    main: () => <Administrators />,
    role: [3]
  },
  {
    path: "/main/usage",
    main: () => <Usage />,
    role: [3]
  },
  {
    path: "/main/billing",
    main: () => <Billing />,
    role: [3]
  }
];

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  toolbarIcon: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    padding: "0 8px",
    ...theme.mixins.toolbar,
  },
  drawerPaper: {
    position: "relative",
    whiteSpace: "nowrap",
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerPaperClose: {
    overflowX: "hidden",
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: theme.spacing(7),
    [theme.breakpoints.up("sm")]: {
      width: theme.spacing(9),
    },
  },
}));

export default function AppSidebar(props) {
  const classes = useStyles();
  const history = useHistory();
  const { role, instituteData } = useContext(AuthContext);
  // console.log(instituteData);
  return (
    <React.Fragment>
      <Drawer
        variant="permanent"
        classes={{
          paper: clsx(
            classes.drawerPaper,
            !props.open && classes.drawerPaperClose
          ),
        }}
        open={props.open}
      >
        <div className={classes.toolbarIcon}>
          <ListItem >
            <ListItemAvatar>
              <Avatar
                alt={instituteData && instituteData.name}
                src={instituteData && instituteData.image || "https://source.unsplash.com/featured/?girl/50"}
              />

            </ListItemAvatar>
            <ListItemText primary={instituteData && instituteData.name && toTitleCase(instituteData.name) || "Super Admin"} secondary="SHIKSHA V0.1" />
          </ListItem>
          {/* <Avatar
            alt="Remy Sharp"
            src={{ url: instituteData.image }}
          />
          <Typography variant="h5" component="h4" align="left">
            {instituteData && instituteData.name && toTitleCase(instituteData.name)}
          </Typography>
          <Typography variant='caption' component="p" align="left">
            Shiksha V0.1
          </Typography> */}
          <div style={{ flexGrow: 1 }} />
          <IconButton onClick={props.handleDrawerClose}>
            <ChevronLeftIcon />
          </IconButton>
        </div>
        <Divider />
        <List>
          {role !== 3 &&
            <>
              <ListItem button onClick={() => history.push("/main/dashboard")}>
                <ListItemIcon>
                  <DashboardIcon />
                </ListItemIcon>
                <ListItemText primary="Dashboard" />
              </ListItem>

              <ListItem button onClick={() => history.push("/main/courses")}>
                <ListItemIcon>
                  <MenuBookOutlinedIcon />
                </ListItemIcon>
                <ListItemText primary="Courses" />
              </ListItem>
            </>
          }

          {role === 0 && (
            <ListItem button onClick={() => history.push("/main/student")}>
              <ListItemIcon>
                <GroupOutlinedIcon />
              </ListItemIcon>
              <ListItemText primary="Students" />
            </ListItem>
          )}

          {role === 0 && (
            <>
              <Divider />
              <ListItem button onClick={() => history.push("/main/payment")}>
                <ListItemIcon>
                  <CreditCard />
                </ListItemIcon>
                <ListItemText primary="Payment" />
              </ListItem>
            </>
          )}
          {
            role === 3 && (
              <>
                <ListItem button onClick={() => history.push("/main/institute")}>
                  <ListItemIcon>
                    <Business />
                  </ListItemIcon>
                  <ListItemText primary="Institute" />
                </ListItem>
                <ListItem button onClick={() => history.push("/main/administrator")}>
                  <ListItemIcon>
                    <SupervisorAccount />
                  </ListItemIcon>
                  <ListItemText primary="Administrator" />
                </ListItem>
                <ListItem button onClick={() => history.push("/main/usage")}>
                  <ListItemIcon>
                    <DataUsage />
                  </ListItemIcon>
                  <ListItemText primary="Usage" />
                </ListItem>
                <ListItem button onClick={() => history.push("/main/billing")}>
                  <ListItemIcon>
                    <MonetizationOn />
                  </ListItemIcon>
                  <ListItemText primary="Billing" />
                </ListItem>
              </>
            )
          }
        </List>
      </Drawer>

      <CourseProvider>
        <Switch>
          {role !== 3 ?
            <StudentProvider>
              <PaymentProvider>
                {routes.map((route, index) => (
                  <Route
                    key={index}
                    path={route.path}
                    exact={route.exact}
                    children={<route.main />}
                  />
                ))}
              </PaymentProvider>
            </StudentProvider>
            :
            <InstituteProvider>
              <AdministratorProvider>
                <UsageProvider>
                  {superAdminRoutes.map((route, index) => (
                    <Route
                      key={index}
                      path={route.path}
                      exact={route.exact}
                      children={<route.main />}
                    />
                  ))}
                </UsageProvider>
              </AdministratorProvider>
            </InstituteProvider>
          }
        </Switch>
      </CourseProvider>
    </React.Fragment>
  );
}
